import _ from 'lodash';
import { persist } from '../utils/helpers';
import { LOGOUT, LOGIN_REQUESTED, LOGIN_RECEIVED, LOGIN_FAILED, SIGNUP_FAILED, SIGNUP_RECEIVED, SIGNUP_REQUESTED } from '../actions';

const initialState = {
  loading: false,
  error: null,
  error_description: null,
  access_token: null,
  expires: null,
  expires_in: null,
  refresh_token: null,
  token_type: null,
};


export const auth = (state = initialState, action) => {
  let new_state;
    
  switch (action.type) {
    case LOGOUT: new_state = persist("auth", _.assignIn({}, state, initialState)); break;
    case LOGIN_REQUESTED: new_state = persist("auth", _.assignIn({}, state)); break;
    case SIGNUP_REQUESTED: new_state = persist("auth", _.assignIn({}, state)); break;
    case LOGIN_RECEIVED: new_state = persist("auth", _.assignIn({}, state, action.payload)); break;
    case SIGNUP_RECEIVED: new_state = persist("auth", _.assignIn({}, state, action.payload)); break;
    case LOGIN_FAILED: new_state = persist("auth", _.assignIn({}, state)); break;
    default: new_state = state; break;
  }
        
  return new_state;
};
