import _ from 'lodash';
import { 
  FETCH_CURRENT_USER_EMAIL_NOTIFICATION_SETTINGS_RECEIVED,
  UPDATE_CURRENT_USER_EMAIL_NOTIFICATION_SETTINGS_RECEIVED,
  FETCH_CURRENT_USER_REQUESTED,
  FETCH_CURRENT_USER_RECEIVED,
  LOGOUT,
  UPDATE_CURRENT_USER_RECEIVED,
  FETCH_CURRENT_USER_COURSES_RECEIVED,
  CREATE_COURSE_RECEIVED,
  CREATE_CURRENT_USER_COURSE_RECEIVED
} from '../actions';
import { persist } from '../utils/helpers';

export const user = (state = {}, action) => {
  let new_state;

  switch (action.type) {
      case LOGOUT: new_state = persist("user", {}); break;
      case FETCH_CURRENT_USER_REQUESTED: new_state = persist("user", _.assignIn({}, state)); break;
      case FETCH_CURRENT_USER_RECEIVED: new_state = persist("user", _.assignIn({}, state, action.payload.user)); break;
      case FETCH_CURRENT_USER_COURSES_RECEIVED: new_state = persist("user", _.assignIn({}, state, {courses: action.payload.courses})); break;
      case CREATE_CURRENT_USER_COURSE_RECEIVED: new_state = _.assignIn({}, state, {courses: _.compact(_.unionBy([action.payload.course], state.courses || [], "id"))}); break;
      case CREATE_COURSE_RECEIVED: new_state = _.assignIn({}, state, {courses: _.compact(_.unionBy([action.payload.course], state.courses || [], "id"))}); break;
      case UPDATE_CURRENT_USER_RECEIVED: new_state = persist("user", _.assignIn({}, state, action.payload.user)); break;
      case FETCH_CURRENT_USER_EMAIL_NOTIFICATION_SETTINGS_RECEIVED: new_state = _.assignIn({}, state, { emailNotificationSettings: action.payload.settings }); break;
      case UPDATE_CURRENT_USER_EMAIL_NOTIFICATION_SETTINGS_RECEIVED: new_state = _.assignIn({}, state, { emailNotificationSettings: action.payload.settings }); break;
      default: new_state = state; break;
  }

  return new_state;
};
