import React from 'react';

import History from './history';
import FirstSlide from './slides';
import TextSlide from './slides/text.slide.tsx';
import Player from './../video-player';

import styles from './carousel.module.scss';

class Carrousel extends React.Component {
  constructor(props) {
    super(props);
    
    this.items = this.props.appMode === "classroom" ? CLASSROOM_SLIDES : CORE_SLIDES

    this.state = {
      current: 0
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown = (e) => {
    switch (e.keyCode) {
      case 37:
        this.handlePrev()
        break;
      case 38:
        this.handlePrev()
        break;
      case 39:
        this.handleNext()
        break;
      case 40:
        this.handleNext()
        break;
      default:
        break;
    } 
  }

  handlePrev = () => {
    this.handleSlideChange(this.state.current ? this.state.current - 1 : 0) //stops at first slide
  }

  handleNext = () => {
    this.handleSlideChange((this.state.current + 1) % this.items.length) // cycles through slides
  }

  handleSlideChange = (index) => {
    this.setState({
      current: index,
    });
    this.props.setCaption(this.items[index].caption);
  }

  render() {
    const currentSlide = this.items[this.state.current]
    const src = window.innerWidth >= 640 ? currentSlide.url : currentSlide.urlMobile; // TODO: make responsive

    return (
      <div className={styles.app}>

        <div className={styles.carousel}>
          <div className={styles.carousel_slide}>
            {src === 'first-slide' ?
                <FirstSlide userName={this.props.userName} />
                : src === 'text-slide' ? <TextSlide />
                : <Player url={src} />
            }
          </div>
          <div className={styles.buttonsWrapper}>
            {this.state.current ?
              <button className={styles.carousel_control + " " + styles.carousel_control__prev} onClick={this.handlePrev}><span>Back</span></button>
              : null}
            {this.state.current < this.items.length - 1 ? 
              <button className={styles.carousel_control + " " + styles.carousel_control__next} onClick={this.handleNext}><span>Next</span></button>
              : <button className={styles.carousel_control + " " + styles.carousel_control__next} onClick={this.props.closeWindow}><span>Close</span></button>}
          </div>
          <div className={styles.carousel_history}>
            <History
              current={this.state.current}
              itemCount={this.items.length}
              changeSlide={this.handleSlideChange}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default Carrousel;

const CORE_SLIDES = [
  { url: 'first-slide', urlMobile: 'first-slide' },
  {
    url: "https://youtu.be/uaibXL_FvO0",
    urlMobile: "https://youtu.be/Bbycpcsfy6Y",
    caption: "To add an article, you must sign in with a real name. Just copy the article’s URL and paste it into the top of the homepage."
  },
  {
    url: 'https://youtu.be/UkKPMsD0UGU',
    urlMobile: "https://youtu.be/5cKG-pTnsIw",
    caption: "Just click any sentence to read or write about it. Or, click the Open/Close All button to do all sentences."
  },
  {
    url: "https://youtu.be/dNkhdU6XLM0",
    urlMobile: "https://youtu.be/ngNsazlplws",
    caption: "Fisk any sentence by adding tags or comments to it. Use your best judgment about which tags apply."
  },
  {
    url: "https://youtu.be/g10YfMt9Xjo",
    urlMobile: "https://youtu.be/zElMAJRXby8",
    caption: "You must add a link to evidence before you can tag a sentence as Provably True or False."
  },
  {
    url: "https://youtu.be/i6Ol0Y8wz0M",
    urlMobile: "https://youtu.be/lQycWElON5M",
    caption: "Fisk other user’s sentences, just like the article! Be polite or it may be tagged as a Personal Attack."
  },
  {
    url: "https://youtu.be/ZHNtM6un1g0",
    urlMobile: "https://youtu.be/iqJYTIrtskA",
    caption: "To isolate just 1 person’s comments, select them from the ISO menu. ISO yourself to hide others’ comments!"
  },
  {
    url: "https://youtu.be/Vb3SZQNa7Zw",
    urlMobile: "https://youtu.be/DI0YOVf8cR4",
    caption: "Share a page with buttons or the URL of the page. Share an ISO’d page to only show one person’s comments."
  },
  {
    url: "https://youtu.be/nEqy0mssMHc",
    urlMobile: "https://youtu.be/flqylV10w4M",
    caption: "If an article receives a lot of fisks, the Insight page will show which tags accurately describe each sentence. (See the FAQ for how this works)."
}]

const CLASSROOM_SLIDES = [
  { url: 'first-slide', urlMobile: 'first-slide' },
  { url: 'text-slide', urlMobile: 'text-slide' },
  {
    url: "https://youtu.be/uaibXL_FvO0",
    urlMobile: "https://youtu.be/Bbycpcsfy6Y",
    caption: "To add an article, you must sign in with a real name. Just copy the article’s URL and paste it into the top of the homepage."
  },
  {
    url: 'https://youtu.be/UkKPMsD0UGU',
    urlMobile: "https://youtu.be/5cKG-pTnsIw",
    caption: "Just click any sentence to read or write about it. Or, click the Open/Close All button to do all sentences."
  },
  {
    url: "https://youtu.be/dNkhdU6XLM0",
    urlMobile: "https://youtu.be/ngNsazlplws",
    caption: "Fisk any sentence by adding tags or comments to it. Use your best judgment about which tags apply."
  },
  {
    url: "https://youtu.be/g10YfMt9Xjo",
    urlMobile: "https://youtu.be/zElMAJRXby8",
    caption: "You must add a link to evidence before you can tag a sentence as Provably True or False."
  },
  {
    url: "https://youtu.be/i6Ol0Y8wz0M",
    urlMobile: "https://youtu.be/lQycWElON5M",
    caption: "Fisk other user’s sentences, just like the article! Be polite or it may be tagged as a Personal Attack."
  },
  {
    url: "https://youtu.be/ZHNtM6un1g0",
    urlMobile: "https://youtu.be/iqJYTIrtskA",
    caption: "To isolate just 1 person’s comments, select them from the ISO menu. ISO yourself to hide others’ comments!"
  },
  {
    url: "https://youtu.be/nEqy0mssMHc",
    urlMobile: "https://youtu.be/flqylV10w4M",
    caption: "If an article receives a lot of fisks, the Insight page will show which tags accurately describe each sentence. (See the FAQ for how this works)."
  },
  {
    url: "https://youtu.be/uy8qK1wYO2k",
    urlMobile: "https://youtu.be/NcfmvLRkP2U",
    caption: "Students can only see articles you’ve added to your course. To add an article, click on Article Options (gear wheel icon)."
  },
  {
    url: "https://youtu.be/MnYCC2pcZOg1",
    urlMobile: "https://youtu.be/fISeBx6JkX4",
    caption: "You can turn off students’ ability to see each other’s fisks, by clicking Article Options (gear wheel icon) and Blinders."
}]