
import React from "react";

const Discussion = ({
  style = {},
  fill = "#091821",
  width = "100%",
  height = "100%",
  className = "",
  viewBox = "0 0 103.06 80.61"
}) => (
    <svg
      width={width}
      style={style}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >

      <path d="M63.44,51.34a.68.68,0,0,0-.13-.8s-1.22-1.18-.91-2.34c.21-.77,1.18-1.85,4.6-2.64,4.36-1,4.83-4.31,4.83-5.68,0-3.39-2.66-6.35-6.58-7.89-4.07,6-12.61,9.4-18,11.06,3.31,5.91,14.92,8.5,15.49,8.62A.67.67,0,0,0,63.44,51.34Z" />
      <path d="M42.5,41.73c2.29-.54,22.44-5.33,22.56-16.84A10.91,10.91,0,0,0,61.66,17c-3.39-3.42-8.9-5.46-14.75-5.46-10,0-18.15,6-18.15,13.29,0,1.68.54,7.27,7.47,8.88,3.59.83,4.77,1.89,4.91,2.39s-.39,1.2-.76,1.56a2.37,2.37,0,0,0-.5,2.78A2.4,2.4,0,0,0,42.5,41.73Zm1.05-3.36a4.07,4.07,0,0,0,.47-3c-.56-2.06-2.89-3.54-7.13-4.53-4.48-1-5.15-4.17-5.15-6,0-5.67,6.8-10.29,15.15-10.29,5.06,0,9.78,1.71,12.62,4.58a8,8,0,0,1,2.53,5.7C62,32.68,48.45,37,43.55,38.37Z" />
      <path d="M30.91,59.51c-8.9,0-16.1,4.31-16.1,11.85v2H47v-2C47,63.82,39.8,59.51,30.91,59.51Z" />
      <circle cx="73.7" cy="54.03" r="6.99" />
      <path d="M73.7,63c-6.65,0-12,3.23-12,8.86v1.5H85.74v-1.5C85.74,66.23,80.35,63,73.7,63Z" />
      <rect x="38.01" y="19.92" width="18.5" height="2.5" />
      <rect x="38.01" y="25.96" width="18.5" height="2.5" />
      <circle cx="30.91" cy="47.5" r="9.36" />
    </svg>
  );

export default Discussion;
