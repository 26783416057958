import React from 'react';

import FiskkitArticles from '../../../resources/img/fiskkit_articles.jpg';
import FiskkitTagDistribution from '../../../resources/img/fiskkit_tag_distribution.jpg';
import FiskkitTags from '../../../resources/img/fiskkit_tags.jpg';
import FiskkitUpload from '../../../resources/img/fiskkit_upload.jpg';

import styles from './tabHow.module.scss';

export default class TabHow extends React.Component {
  public render() {
    return (
      <div>
        <div className={styles.tabGridContainer}>
          <div className={styles.gridContainerItem}>
            <div className={styles.imageBorder}><img src={FiskkitUpload} alt="fiskkitGraph" /></div>
            <div className={styles.number}>1</div>
            <div
              className={styles.modalParagraph}
            >
              {/* tslint:disable-next-line:max-line-length */}
              Take an online article that you want to comment on, copy and paste the link into Fiskkit. This allows you to input the article into our system for you to comment on.
            </div>
          </div>
          <div className={styles.gridContainerItem}>
            <div
              className={styles.imageBorder}
              style={{ marginTop: '-35px' }}
            >
              <img src={FiskkitArticles} alt="fiskkitGraph" />
            </div>
            <div className={styles.or}>OR</div>
            <div
              className={styles.modalParagraph}
              style={{ marginTop: '-8px' }}
            >
              Click on an article you find interesting.
            </div>
          </div>
          <div className={styles.gridContainerItem}>
            <div className={styles.imageBorder}><img src={FiskkitTags} alt="fiskkitGraph" /></div>
            <div className={styles.number}>2</div>
            <div
              className={styles.modalParagraph}
            >
              {/* tslint:disable-next-line:max-line-length */}
              Rate any sentence inside the article by clicking on a sentence & choosing tags that best describe it. Add comments to support your arguments.
            </div>
          </div>
          <div className={styles.gridContainerItem}>
            <div
              className={styles.imageBorder}
            >
              <img src={FiskkitTagDistribution} alt="fiskkitGraph" />
            </div>
            <div className={styles.number}>3</div>
            <div
              className={styles.modalParagraph}
            >
              {/* tslint:disable-next-line:max-line-length */}
              See how the article has been rated by other people through our insights page. Share the article so that your friends can come comment too.
            </div>
          </div>
        </div>
      </div>
    );
  }
}
