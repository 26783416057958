export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const openModal = (id) => ({
    type: OPEN_MODAL,
    payload: {id, show: true}
});
export const closeModal = (id) => ({
    type: CLOSE_MODAL,
    payload: {id, show: false}
});
