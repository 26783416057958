import React from "react";
import ReactDom from "react-dom";
import StyledModal from "./intromodal.module.scss";
import Carrousel from "./video-carousel";


class ModalIntro extends React.Component {
  constructor(props){
    super(props)
    this.background = React.createRef();
    this.state = { fadeType: null, currentCaption: ''};
  }

  componentDidMount() {
    window.addEventListener("keydown", this.onEscKeyDown, false);

    setTimeout(() => this.setState({ fadeType: "in" }), 200);
  }

  componentDidUpdate(prevProps) {
      if (!this.props.isOpen && prevProps.isOpen) {
        this.setState({ fadeType: "out" });
      }
  }

  componentWillUnmount() {
      window.removeEventListener("keydown", this.onEscKeyDown, false);

      if (this.state.fadeType === "out") {
            this.props.onClose(true);
          }
  }

  setCaptionHeaderTitle = (caption) => {
      this.setState({currentCaption: caption})
  }

  transitionEnd = e => {
    if (e.propertyName !== "opacity" || this.state.fadeType === "in") return;
    if (this.state.fadeType === "out") {
        this.props.onClose(false);
      }
  }

  onEscKeyDown = e => {
      if (e.key !== "Escape") return;
      this.setState({ fadeType: "out" });
  };

  handleClick = e => {
      e.preventDefault();
      this.setState({ fadeType: "out" });
  };

  render() {
    return ReactDom.createPortal((
      <div
            id={this.props.id}
            className={this.state.fadeType === "in" ? StyledModal.modalFade + " " +StyledModal.fadeIn : StyledModal.modalFade + " " + StyledModal.fadeOut}
            role="dialog"
            onTransitionEnd={this.transitionEnd}
      >
        <div className={StyledModal.boxDialog}>
          <div className={StyledModal.boxHeader}>
            <p className={StyledModal.caption}>
              {this.state.currentCaption}
            </p>
            <h4 className={StyledModal.boxTitle}>
              {/* TODO: remove unnecessary elements and restyle */}
            </h4>

            <button onClick={this.handleClick} className={StyledModal.xClose}>×</button>
          </div>
          <div className={StyledModal.boxContent}>
            <Carrousel closeWindow={this.handleClick} userName={this.props.userName} setCaption={this.setCaptionHeaderTitle} appMode={this.props.appMode}/>
          </div>
          <div className={StyledModal.boxFooter}>
          </div>
        </div>
        <div
          className={StyledModal.background}
          onMouseDown={this.handleClick}
          ref={this.background}
        ></div>
      </div>),
      document.getElementById("intro-modal-videos")
    );
  }
}

export default ModalIntro;
