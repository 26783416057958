import React from 'react';
import styles from './style.module.scss'

const ProfilePicture = (props) => {
  const { avatar, first_name, last_name, name } = props.user;

  const userInitials = (
    <div className={styles.UserInitials}>
      {first_name.substr(0,1).toUpperCase()}{last_name.substr(0,1).toUpperCase()}
    </div>
  );

  // NOTE: Set the pic height using "font-size" on a container element
  //       in the parent component. The pic height and width will be the value set.
  //       This is to scale the initials with the pic circle.
  return avatar
    ? (<object className={styles.UserPic} data={avatar} alt={name} type="image/png">
        {userInitials}
      </object>)
    : <div className={styles.UserPic}>{userInitials}</div>;
}

export default ProfilePicture;
