import { httpFiskkitAction } from './util';

export const FETCH_COURSE_INSIGHTS = 'FETCH_COURSE_INSIGHTS';
export const FETCH_COURSE_INSIGHTS_FAILED = 'FETCH_COURSE_INSIGHTS_FAILED';
export const FETCH_COURSE_INSIGHTS_RECEIVED = 'FETCH_COURSE_INSIGHTS_RECEIVED';
export const FETCH_COURSE_INSIGHTS_REQUESTED = 'FETCH_COURSE_INSIGHTS_REQUESTED';
 
export const fetchCourseInsights = (courseId: string): any => {
  return httpFiskkitAction({
    method: 'GET',
    url: `/insights/${courseId}`,
    type: FETCH_COURSE_INSIGHTS,
  });
}