export const pages = [
  { id: "1", title: "About", path: "/about" },
  { id: "3", title: "FAQ", path: "/faq" },
  { id: "4", title: "Terms Of Service", path: "/terms" },
  { id: "5", title: "Our Team", path: "/team" },
  { id: "6", title: "Data and Privacy", path: "/privacy" },
];

export const ourTeam = [
  {
    id: "1",
    path: "JP",
    alt: "Photo of John Pettus",
    title: "John Pettus",
    position: "Founder, CEO",
    description:
      "John is a native San Franciscan and lifelong news junkie. After college, and 30 days before 9/11, he enlisted in the army and built the first civil information management system during his first tour in Iraq. After working in private equity, and after leaving the army as a Captain, John designed and founded Fiskkit to raise the level of discussion online, and create accountability for our civic leaders. John has a BA in Economics and East Asian Studies from Stanford, and an MBA from UC Berkeley.",
  },
  {
    id: "2",
    path: "Ross",
    alt: "Photo of Ross Baquir",
    title: "Ross Baquir",
    position: "Developer",
    description:
      "Ross is a software developer who believes function and form go hand in hand and that code can be as beautiful as the software it creates. He holds a BA in Japanese with a background in design and photography. Outside of code you’ll usually find him tinkering with gadgets, playing guitar, with a camera, or chasing his daughter around the house. He enjoys coffee, ice cream, bike rides, and all things Japan.",
  },
  {
    id: "3",
    path: "Tammu",
    alt: "Photo of Tammu",
    title: "Tammu Do",
    position: "Designer",
    description:
      "Tammu is a designer intrigued and driven by the end-to-end experience potentials of Culture, Materiality, and Technology in order to initiate and sustain reciprocal social design. She has a range of design experience in products, speculative design projects, and VR and AR projects. Much of her work before and now has elements of playfulness, and so it's not much of a surprise that she obtained her Game Design masters at Goldsmiths University. Eager to find more affordances with these intersecting bridges of games, design, and technology, Tammu is continually creating frameworks on design thinking and development for products and experiences.",
  },
  {
    id: "4",
    path: "Hakeem",
    alt: "Photo of Hakeem Almidan",
    title: "Hakeem Almidan",
    position: "Developer",
    description:
      "Abdulhakeem (Hakeem) is an App Academy programming bootcamp graduate. He also holds a bachelor's degree in finance from Oregon State University. He enjoys programming using many technologies, including Ruby on Rails, React, and Redux. Outside of work, he likes to spend his time painting, playing volleyball, scuba diving, browsing / participating in Fiskkit.com, and hanging out with friends.",
  },
  {
    id: "5",
    path: "Erica",
    alt: "Photo of Erica Guerra",
    title: "Erica Guerra",
    position: "Growth Team",
    description: "Erica",
  },
  {
    id: "6",
    path: "JoeG",
    alt: "Photo of Joseph Guvendiren",
    title: "Joseph Guvendiren",
    position: "Growth Team",
    description:
      "Joe G is a Student Affairs practitioner and Leadership Educator attaining his Master's Degree at Central Michigan University.  He is a lifelong Massachusetts resident bringing some East Coast Bias and enthusiasm for community development to the Fiskkit team. Relevant interests: critical political commentary, radical candor, geography. Irrelevant interests: board games, hockey, dishing out red cards to child athletes on the weekends.",
  },
  {
    id: "7",
    path: "Tanvi",
    alt: "Photo of Tanvi Kamble",
    title: "Tanvi Kamble",
    position: "Developer",
    description:
      "Tanvi is a sophomore currently enrolled in IIIT-Hyderabad, India and is a Computational Linguistics undergraduate researcher. She is passionate about NLP and aims to become a successful research scientist one day. She enjoys being a developer and is always eager to learn new things. She likes to spend most of her time with dogs. You would usually find her in a bakery or at an ice-cream parlour with a huge scoop of chocolate ice-cream.",
  },
  {
    id: "8",
    path: "Moughees",
    alt: "Photo of Moughees Ahmed",
    title: "Moughees Ahmed",
    position: "Developer",
    description:
      "Moughees just graduated college in 2020 - (unfortunately) and if he's not watching John Oliver, he's usually asleep and occasionally working as a Research Assistant in NLP and speech technology. Super driven to solve misinformation at scale to help people be better informed. He is a huge Manchester United fan and is often found crying about his team's misfortunes. Super excited to be at Fiskkit!",
  },
  {
    id: "9",
    path: "Victoria",
    alt: "Photo of Victoria Galvan",
    title: "Victoria Galvan",
    position: "Developer",
    description:
      "Victoria is an engineer captivated by cybersecurity, social activism, and computation. She is currently enrolled at the University of Central Florida where she studies Computer Science and works as a research assistant. She is passionate about her work and the practice of mindfulness. When Victoria is not working on a project, you will likely find her backpacking in a remote mountain range or reciting poetry at a local coffee shop. "
  },
];
