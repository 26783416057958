import { httpFiskkitAction } from "./util";

export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const UPDATE_CURRENT_USER_REQUESTED = 'UPDATE_CURRENT_USER_REQUESTED';
export const UPDATE_CURRENT_USER_RECEIVED = 'UPDATE_CURRENT_USER_RECEIVED';
export const UPDATE_CURRENT_USER_FAILED = 'UPDATE_CURRENT_USER_FAILED';
export const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER';
export const FETCH_CURRENT_USER_REQUESTED = 'FETCH_CURRENT_USER_REQUESTED';
export const FETCH_CURRENT_USER_RECEIVED = 'FETCH_CURRENT_USER_RECEIVED';
export const FETCH_CURRENT_USER_FAILED = 'FETCH_CURRENT_USER_FAILED';
export const FETCH_CURRENT_USER_COURSES = 'FETCH_CURRENT_USER_COURSES';
export const FETCH_CURRENT_USER_COURSES_REQUESTED = 'FETCH_CURRENT_USER_COURSES_REQUESTED';
export const FETCH_CURRENT_USER_COURSES_RECEIVED = 'FETCH_CURRENT_USER_COURSES_RECEIVED';
export const FETCH_CURRENT_USER_COURSES_FAILED = 'FETCH_CURRENT_USER_COURSES_FAILED';
export const CREATE_CURRENT_USER_COURSE = 'CREATE_CURRENT_USER_COURSE';
export const CREATE_CURRENT_USER_COURSE_REQUESTED = 'CREATE_CURRENT_USER_COURSE_REQUESTED';
export const CREATE_CURRENT_USER_COURSE_RECEIVED = 'CREATE_CURRENT_USER_COURSE_RECEIVED';
export const CREATE_CURRENT_USER_COURSE_FAILED = 'CREATE_CURRENT_USER_COURSE_FAILED';
export const FETCH_CURRENT_USER_EMAIL_NOTIFICATION_SETTINGS = 'FETCH_CURRENT_USER_EMAIL_NOTIFICATION_SETTINGS';
export const FETCH_CURRENT_USER_EMAIL_NOTIFICATION_SETTINGS_RECEIVED = 'FETCH_CURRENT_USER_EMAIL_NOTIFICATION_SETTINGS_RECEIVED';
export const UPDATE_CURRENT_USER_EMAIL_NOTIFICATION_SETTINGS = 'UPDATE_CURRENT_USER_EMAIL_NOTIFICATION_SETTINGS';
export const UPDATE_CURRENT_USER_EMAIL_NOTIFICATION_SETTINGS_RECEIVED = 'UPDATE_CURRENT_USER_EMAIL_NOTIFICATION_SETTINGS_RECEIVED';


export function fetchCurrentUser() {
  return (dispatch, getState) => {
    if (getState().auth.access_token) {
      return dispatch(httpFiskkitAction({
        method: 'GET',
        url: '/users/me',
        type: FETCH_CURRENT_USER, 
      }));
    }
  }
}

export function fetchCurrentUserEmailNotificationSettings() {
  return httpFiskkitAction({
    method: 'GET',
    url: '/userNotificationSettings',
    type: FETCH_CURRENT_USER_EMAIL_NOTIFICATION_SETTINGS,
  })
}

export function updateCurrentUserEmailNotificationSettings(data) {
  return httpFiskkitAction({
    method: 'PUT',
    url: '/userNotificationSettings',
    type: UPDATE_CURRENT_USER_EMAIL_NOTIFICATION_SETTINGS,
    data: data,
    successMessage: 'Your email notification settings were updated'
  });
}

export function updateCurrentUser(data) {
  return httpFiskkitAction({
    method: 'PUT',
    url: '/users/me',
    type: UPDATE_CURRENT_USER, 
    data: data,
    successMessage: "Your settings were updated",
  });
}

export function fetchCurrentUserCourses() {
  return (dispatch, getState) => {
    const { id } = getState().user;
    return dispatch(httpFiskkitAction({
      method: 'GET',
      url: '/users/'+id+'/courses',
      type: FETCH_CURRENT_USER_COURSES, 
    }));
  }
}

export function createUserCourse(data) {
  return httpFiskkitAction({
    method: 'POST',
    url: '/course/addmember',
    type: CREATE_CURRENT_USER_COURSE, 
    data: data,
    successMessage: "Course was added",
  });
}
