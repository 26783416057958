import { httpFiskkitAction } from "./util";

export const CREATE_COURSE = 'CREATE_COURSE';
export const CREATE_COURSE_FAILED = 'CREATE_COURSE_FAILED';
export const CREATE_COURSE_RECEIVED = 'CREATE_COURSE_RECEIVED';
export const CREATE_COURSE_REQUESTED = 'CREATE_COURSE_REQUESTED';
export const FETCH_COURSES = 'FETCH_COURSES';
export const FETCH_COURSES_FAILED = 'FETCH_COURSES_FAILED';
export const FETCH_COURSES_RECEIVED = 'FETCH_COURSES_RECEIVED';
export const FETCH_COURSES_REQUESTED = 'FETCH_COURSES_REQUESTED';

export function createCourse(data) {
    return httpFiskkitAction({
        method: 'POST',
        url: '/course/add', 
        data: data,
        type: CREATE_COURSE, 
    });
}

export function fetchCourses() {
    return httpFiskkitAction({
        method: 'GET',
        url: '/course/all', 
        type: FETCH_COURSES, 
    });
}