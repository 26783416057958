// import _ from 'lodash';
import _ from 'lodash';
import { FETCH_MOST_FISKED_REQUESTED, FETCH_MOST_FISKED_RECEIVED, FETCH_MOST_RECENT_REQUESTED, FETCH_MOST_RECENT_RECEIVED, TOGGLE_FOCUSED, SET_ACTIVE_TAB, SET_SHOW_MOST_RECENT, SET_SHOW_EDIT_FIELD, SET_ARTICLE_URL, SET_ALL_COURSES, SET_COURSE_VALUE, SET_CURRENT_USER, SET_CURRENT_USER_COURSES, SET_NEW_COURSE, SET_INPUT_VALUE_HOMEPAGE, SET_SHOW_MOST_FISKED, FETCH_ARTICLE_SUBMISSION_STATUS_RECEIVED, FETCH_ARTICLE_SUBMISSION_STATUS_REQUESTED, CREATE_ARTICLE_SUBMISSION_REQUESTED, CREATE_ARTICLE_SUBMISSION_RECEIVED, RESET_ARTICLE_SUBMISSION, FETCH_ARTICLES_IN_MY_COURSES_REQUESTED, FETCH_ARTICLES_IN_MY_COURSES_RECEIVED } from '../actions';

// merges fetched articles in descending order by specified property and removes duplicates.
// Doing this allows fetching articles for the top of the list when the list mounts,
// as well as removing any duplicate articles due to shifted offset from new articles or changed order.
const mergePayload = (sortBy, list, payload) => _.uniqBy(
  _.orderBy([...list, ...payload.articles], sortBy, 'desc'), 'id');

export const initialState = {
  articlesInMyCourses: {},
  articlesMostFisked: {},
  articlesMostRecent: {},
  articleSubmission: {},
  articleSubmissionStatus: {},
  accountSettingsData: {
    bio: "",
    location: "",
  },
  allCourses: [],
  articleUrl: '',
  error: '',
  focused: false,
  courseValue: 40,
  showMostFisked: false,
  showMostRecent: true,
  sidebarOpen: true,
  activeTab: 'articles-in-my-courses',
  currentUser: {},
  currentUserCourses: [],
  newCourse: '',
  showBioEdit: false,
  showLocationEdit: false,
};

export const home =
  (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {

      case RESET_ARTICLE_SUBMISSION:
      case CREATE_ARTICLE_SUBMISSION_REQUESTED:
        return _.assignIn({}, state, {articleSubmission: {}, articleSubmissionStatus: {} });
      case CREATE_ARTICLE_SUBMISSION_RECEIVED:
        return _.assignIn({}, state, {articleSubmission: payload });

      case FETCH_ARTICLE_SUBMISSION_STATUS_RECEIVED:
        return _.assignIn({}, state, {articleSubmissionStatus: payload });

      case FETCH_ARTICLES_IN_MY_COURSES_REQUESTED:
        return _.assignIn({}, state);
      case FETCH_ARTICLES_IN_MY_COURSES_RECEIVED:
        return _.assignIn({}, state, {articlesInMyCourses: { data: action.payload }});

      case FETCH_MOST_FISKED_REQUESTED:
        return _.assignIn({}, state);
      case FETCH_MOST_FISKED_RECEIVED:
        const stateMostFisked = _.get(state.articlesMostFisked, ['data', 'articles']);

        const newMostFisked = stateMostFisked ? mergePayload('fisk_count', stateMostFisked, payload) : payload.articles;
        const articlesMostFisked = {
          data: {
            meta: payload.meta,
            articles: newMostFisked,
          }
        };

        return _.assignIn({}, state, { articlesMostFisked });

      case FETCH_MOST_RECENT_REQUESTED:
        return _.assignIn({}, state);
      case FETCH_MOST_RECENT_RECEIVED:
        const stateMostRecent = _.get(state.articlesMostRecent, ['data', 'articles']);

        const newMostRecent = stateMostRecent ? mergePayload('created_at', stateMostRecent, payload) : payload.articles;

        const articlesMostRecent = {
          data: {
            meta: payload.meta,
            articles: newMostRecent,
          }
        };

        return _.assignIn({}, state, { articlesMostRecent });



      case TOGGLE_FOCUSED:
        return {
          ...state,
          focused: !state.focused,
        };

      case SET_ACTIVE_TAB:
        const activeTab = action.payload;
        return {
          ...state,
          activeTab,
        };

      case SET_SHOW_MOST_RECENT:
        const showMostRecent = payload;
        return {
          ...state,
          showMostRecent,
        };

      case SET_SHOW_MOST_FISKED:
        const showMostFisked = payload;
        return {
          ...state,
          showMostFisked,
        };

      case SET_SHOW_EDIT_FIELD:
        const name = payload.name;
        const value = payload.value;
        return {
          ...state,
          [`${name}`]: value,
        };

      case SET_ARTICLE_URL:
        const articleUrl = payload;
        return {
          ...state,
          articleUrl,
        };

      case SET_ALL_COURSES:
        const allCourses = payload;
        return {
          ...state,
          allCourses,
        };

      case SET_COURSE_VALUE:
        const courseValue = payload;
        return {
          ...state,
          courseValue,
        };

      // case SET_ACCOUNT_SETTINGS_DATA:
      //   const accountSettingsData = payload;
      //   return {
      //     ...state,
      //     accountSettingsData,
      //   };

      case SET_CURRENT_USER:
        const currentUser = payload;
        return {
          ...state,
          currentUser,
        };

      case SET_CURRENT_USER_COURSES:
        const currentUserCourses = payload;
        return {
          ...state,
          currentUserCourses,
        };

      case SET_NEW_COURSE:
        const newCourse = payload;
        return {
          ...state,
          newCourse,
        };

      case SET_INPUT_VALUE_HOMEPAGE:
        const newSettings = payload; // {name: ..., value: ...}
        const accountData = {
          ...state.accountSettingsData,
          [`${newSettings.name}`]: newSettings.value,
        };
        return {
          ...state,
          accountSettingsData: accountData,
        };

      default:
        return state;
    }
  };
