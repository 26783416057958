import _ from 'lodash';
import { SIGNUP_FAILED, SIGNUP_RECEIVED, LOGOUT, LOGIN_REQUESTED, LOGIN_RECEIVED, LOGIN_FAILED } from '../actions';

export function signup(state = {}, action) {
	switch (action.type) {
        case LOGOUT:
        case LOGIN_REQUESTED:
        case LOGIN_RECEIVED:
        case LOGIN_FAILED: 
			return _.assignIn({}, state, {attempt_login: false});
		// case SIGNUP_RECEIVED:
		// 	return _.assignIn({}, state, action.payload, {attempt_login: true});
		default:
			return state;
	}
}