import React from 'react';
import { connect } from 'react-redux';
import ProfilePicture from '../../etc/profile-picture';
import Dropdown from '../dropdown';
import DropdownItem from '../dropdown/dropdown-item';
import OnlyClassroom from '../only-classroom';
import OnlyCore from '../only-core';
import OnlyInstructors from '../only-instructors';

import { openModal, showVideos, logout } from '../../../actions';
import { MODAL_LOGIN } from '../../../constants';
import styles from './toolbar.module.scss';

const USER_LINKS = [
  { title: "Settings", path: "/user/settings" },
];

const UserMenu = ({ user, logout, showVideos, openModal }) => (
  user.id
  ? (
    <React.Fragment>
      <div style={{ fontSize: '40px', marginRight: '0.25em' }}>
        <ProfilePicture user={user} />
      </div>
      <Dropdown menuStyle={{ right: 35 }} label={user.name} labelClassName={styles.fromTabletUp} labelStyle={{ fontSize: '1.1em' }}>
        {USER_LINKS.map((link, index) => <DropdownItem key={`usermenu${index}`} linkTo={link.path}>{link.title}</DropdownItem>)}
        <DropdownItem onClick={() => showVideos()}>Tutorial Videos</DropdownItem>
        <OnlyClassroom>
          <OnlyInstructors>
            <DropdownItem linkTo="/user/classinsights">Class Insights</DropdownItem>
          </OnlyInstructors>
          <DropdownItem linkTo="/logout">Logout</DropdownItem>
        </OnlyClassroom>
        <OnlyCore>
          <DropdownItem onClick={logout}>Logout</DropdownItem>
        </OnlyCore>
      </Dropdown>
    </React.Fragment>
  ) : <OnlyCore><button className="button is-primary" onClick={() => openModal(MODAL_LOGIN)}>Sign In</button></OnlyCore>
)

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (id) => dispatch(openModal(id)),
    showVideos: () => dispatch(showVideos()),
    logout: () => dispatch(logout())
  };
};

export default connect(null, mapDispatchToProps)(UserMenu);
