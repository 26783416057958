import { Notify } from 'react-redux-notify';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Toolbar from './toolbar';
import ModalIntroVideo from '../modal/modal-intro-video/';
import {showVideos, closeVideos} from '../../actions';
import styles from './layout.module.scss';
import Modal from '../modal';
import ModalLogin from '../modal/modal-login';
import { MODAL_WHAT_IS_FISKKIT, MODAL_LOGIN } from '../../constants';
import TabHow from '../etc/tabHow';
import TabMission from '../etc/tabMission';
import userVoiceWidget from '../../utils/userVoice';
import facebookInit from '../../utils/facebook';


interface IState {
  modalTab: string;
}

interface ILayoutProps {
  modals: { whatIsFiskkitModal: boolean };
  error: any;
  loading: boolean;
  tabHow: boolean;
  user: any;
  FACEBOOK_APP_ID: string;
  show_videos: any;
  closeVideos() : void;
  showVideos(): void;
  app: any;
  intervalId: any;

  toggleModal(): void;
  toggleHowTab(): void;
  toggleMissionTab(): void;
  openModal(arg0: string): any;
}

const HOW_IT_WORKS = "HOW_IT_WORKS";
const OUR_MISSION = "OUR_MISSION";

class Layout extends React.Component<ILayoutProps, IState> {
  intervalId: any;
  public state: IState = {
    modalTab: "HOW_IT_WORKS",
  };

  public componentDidMount(){
    this.videoGalleryReady();
    if ((window as any).UserVoice) userVoiceWidget(this.props.user);
    if ((window as any).FB) facebookInit(this.props.FACEBOOK_APP_ID);
  }

  public componentDidUpdate(prevProps) {
    if ((window as any).UserVoice && _.get(this.props, 'user') !== _.get(prevProps, 'user')) {
      userVoiceWidget(this.props.user);
    }
    this.videoGalleryReady();
  }

  public closeModal = () => this.props.closeVideos();

  public videoGalleryReady = () =>{
    if(!localStorage.getItem("ModalVideoGallery") && this.props.user.first_name !== undefined){
      this.props.showVideos()
      localStorage.setItem("ModalVideoGallery", 'true')
    }
  }

  public render() {
    const { modalTab } = this.state;

    return (
      <React.Fragment>
        <Toolbar />

        <Modal id={MODAL_WHAT_IS_FISKKIT} title="What is Fiskkit?" submit={null} cancel={() => {}}>
          <div className="tabs is-centered">
            <ul>
              <li onClick={e => this.setState({modalTab:HOW_IT_WORKS})}><a>How It Works?</a></li>
              <li onClick={e => this.setState({modalTab:OUR_MISSION})}><a>Our Mission</a></li>
            </ul>
          </div>
          <div>
            {modalTab===HOW_IT_WORKS ? <TabHow />     : null}
            {modalTab===OUR_MISSION  ? <TabMission /> : null}
          </div>

        </Modal>

        <ModalLogin id={MODAL_LOGIN} />
        {this.props.show_videos && (
            <ModalIntroVideo
              isOpen={this.props.show_videos}
              onClose={this.closeModal}
              userName={this.props.user.first_name}
              appMode={this.props.app.APP_EXPERIENCE}
            >
              <div className="boxBody">I am the content of the modal</div>
            </ModalIntroVideo>
          )}

        <main className={styles.mainClass}>
          <Notify position={'CustomNotificationPosition'} customStyles={{containerCustomNotificationPosition: 'CustomNotificationPosition'}}  />

          {this.props.children}
        </main>
      </React.Fragment>
    );
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    app: state.app,
    user: state.user,
    FACEBOOK_APP_ID: state.app.FACEBOOK_APP_ID,
    show_videos : state.videos.show_videos,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showVideos: () => { dispatch(showVideos()); },
    closeVideos: () => { dispatch(closeVideos()); }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Layout);


{/* <Modal
  show={this.props.modals.whatIsFiskkitModal}
  // tslint:disable-next-line:jsx-no-lambda
  onClose={() => { this.toggleFiskkitModal('TOGGLE_FISKKIT_MODAL'); }}
  // tslint:disable-next-line:jsx-no-lambda
  onHowClick={() => { this.toggleFiskkitModal('TOGGLE_FISKKIT_MODAL_HOW'); }}
  // tslint:disable-next-line:jsx-no-lambda
  onMissionClick={() => { this.toggleFiskkitModal('TOGGLE_FISKKIT_MODAL_MISSION'); }}
>
  {/* tslint:disable-next-line:jsx-no-multiline-js */}
//   {
//     this.props.tabHow
//       ?
//       <TabHow />
//       :
//       <TabMission />}
// </Modal> */}
