import React from 'react';
import axios from 'axios';
import {createNotification, NOTIFICATION_TYPE_ERROR} from 'react-redux-notify';
import { HTTP_ACTION, LOGOUT } from '../actions';
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NOTIFICATION_TYPE_SUCCESS } from 'react-redux-notify/lib/modules/Notifications';

const http = store => next => action => {
    if (action[HTTP_ACTION]) {
        const { data, params, type, successMessage } = action[HTTP_ACTION];
        let { headers, method, url } = action[HTTP_ACTION];
        
        next({
            type: type + "_REQUESTED"
        });
        
        return axios({ method, url, headers, data, params })
            .then(response => handleSuccess(next, type + "_RECEIVED", response, successMessage))
            .catch(error => handleCatch(next, type + "_FAILED", error));
    }

    return next(action);
}

function normalizeError(errorObj) {
    // Pull in the API error if it exists...
    let message = null, errors = null;
    message = _.get(errorObj, "response.data.error", errorObj.message)
    message = _.get(errorObj, "response.data.error_description", message)
    message = _.get(errorObj, "response.data.error.message", message)
    message = _.get(errorObj, "response.data.message", message)
    errors = _.get(errorObj, "response.data.error.errors", errors)
    errors = _.get(errorObj, "response.data.errors", errors)
    return { ...errorObj, message, errors }
}

function notifyAndDispatchError(dispatch, actionType, errorObj) {
    dispatchErrorNotification(dispatch, actionType, errorObj);
    dispatchError(dispatch, actionType, errorObj);
    // throw errorObj; // use for debugging purposes
}

function dispatchError(dispatch, actionType, errorObj) {
    let { name, message, errors } = normalizeError(errorObj);
    if (errors) {
        for(let name in errors) {
            errors[name].forEach(message => {
                dispatch({
                    type: actionType,
                    error: {
                        name,
                        message,
                    }
                });
            })
        }
    } else {
        dispatch({
            type: actionType,
            error: {
                name,
                message,
            }
        });

    }
}

function dispatchErrorNotification(dispatch, actionType, errorObj) {
    let { message, errors } = normalizeError(errorObj);
    if(errors != null) {
        for(let name in errors) {
            errors[name].forEach(message => {
                dispatch(createNotification({
                    message: message,
                    type: NOTIFICATION_TYPE_ERROR,
                    duration: 4000,
                    canDismiss: true,
                    icon: <FontAwesomeIcon icon='exclamation-circle' />
                }));
            })
        }
    } else if (message.trim() !== '') {
        dispatch(createNotification({
            message: message,
            type: NOTIFICATION_TYPE_ERROR,
            duration: 4000,
            canDismiss: true,
            icon: <FontAwesomeIcon icon='exclamation-circle' />
        }));
    }
}

function dispatchSuccessNotification(dispatch, message) {
    dispatch(createNotification({
        message: message,
        type: NOTIFICATION_TYPE_SUCCESS,
        duration: 4000,
        canDismiss: true,
        icon: <FontAwesomeIcon icon='check-circle' />
    }));
}

function handleCatch(dispatch, actionType, errorObj) {
    // Remove red error messages when double clicking Respect button or saving an empty fisk
    if (actionType === "CREATE_FISK_RESPECT_FAILED" || actionType === "DELETE_FISK_RESPECT_FAILED" || actionType === "CREATE_FISK_FAILED") {
        return;
    }
    if (errorObj.response) {
        return handleErrorResponse(dispatch, actionType, errorObj)
    } else if (errorObj.request) {
        return handleErrorRequest(dispatch, actionType, errorObj)
    } else {
        return handleError(dispatch, actionType, errorObj)
    }
}

function handleSuccess(dispatch, actionType, response, successMessage) {
    if (successMessage) {
        dispatchSuccessNotification(dispatch, successMessage);
    }
    dispatch({ type: actionType, payload: response.data })
}

const handleError = (...args) => notifyAndDispatchError(...args);

const handleErrorRequest = (...args) => notifyAndDispatchError(...args);

const handleErrorResponse = (dispatch, actionType, errorObj) => {
    if ([401, 403].indexOf(errorObj.response.status) > -1) {
        dispatch({
            type: LOGOUT
        });
        dispatchError(dispatch, actionType, errorObj);
    } else {
        notifyAndDispatchError(dispatch, actionType, errorObj);
    }
}

export default http;
