import { httpFiskkitAction } from "./util";

export const CREATE_ARTICLE_SUBMISSION = 'CREATE_ARTICLE_SUBMISSION';
export const CREATE_ARTICLE_SUBMISSION_FAILED = 'CREATE_ARTICLE_SUBMISSION_FAILED';
export const CREATE_ARTICLE_SUBMISSION_REQUESTED = 'CREATE_ARTICLE_SUBMISSION_REQUESTED';
export const CREATE_ARTICLE_SUBMISSION_RECEIVED = 'CREATE_ARTICLE_SUBMISSION_RECEIVED';
export const FETCH_ARTICLE_SUBMISSION_STATUS = 'FETCH_ARTICLE_SUBMISSION_STATUS';
export const FETCH_ARTICLE_SUBMISSION_STATUS_FAILED = 'FETCH_ARTICLE_SUBMISSION_STATUS_FAILED';
export const FETCH_ARTICLE_SUBMISSION_STATUS_REQUESTED = 'FETCH_ARTICLE_SUBMISSION_STATUS_REQUESTED';
export const FETCH_ARTICLE_SUBMISSION_STATUS_RECEIVED = 'FETCH_ARTICLE_SUBMISSION_STATUS_RECEIVED';
export const FETCH_MOST_FISKED = 'FETCH_MOST_FISKED';
export const FETCH_MOST_FISKED_FAILED = 'FETCH_MOST_FISKED_FAILED';
export const FETCH_MOST_FISKED_REQUESTED = 'FETCH_MOST_FISKED_REQUESTED';
export const FETCH_MOST_FISKED_RECEIVED = 'FETCH_MOST_FISKED_RECEIVED';
export const FETCH_MOST_RECENT = 'FETCH_MOST_RECENT';
export const FETCH_MOST_RECENT_REQUESTED = 'FETCH_MOST_RECENT_REQUESTED';
export const FETCH_MOST_RECENT_RECEIVED = 'FETCH_MOST_RECENT_RECEIVED';
export const FETCH_MOST_RECENT_FAILED = 'FETCH_MOST_RECENT_FAILED';
export const FETCH_ARTICLES_IN_MY_COURSES = 'FETCH_ARTICLES_IN_MY_COURSES';
export const FETCH_ARTICLES_IN_MY_COURSES_FAILED = 'FETCH_ARTICLES_IN_MY_COURSES_FAILED';
export const FETCH_ARTICLES_IN_MY_COURSES_REQUESTED = 'FETCH_ARTICLES_IN_MY_COURSES_REQUESTED';
export const FETCH_ARTICLES_IN_MY_COURSES_RECEIVED = 'FETCH_ARTICLES_IN_MY_COURSES_RECEIVED';
export const RESET_ARTICLE_SUBMISSION = 'RESET_ARTICLE_SUBMISSION';
export const SET_ACCOUNT_SETTINGS_DATA = 'SET_ACCOUNT_SETTINGS_DATA';
export const SET_ALL_COURSES = 'SET_ALL_COURSES';
export const SET_ARTICLE_URL = 'SET_ARTICLE_URL';
export const SET_COURSE_VALUE = 'SET_COURSE_VALUE';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_INPUT_VALUE_HOMEPAGE = 'SET_INPUT_VALUE_HOMEPAGE';
export const SET_NEW_COURSE = 'SET_NEW_COURSE';
export const SET_CURRENT_USER_COURSES = 'SET_CURRENT_USER_COURSES';
export const SET_SHOW_EDIT_FIELD = 'SET_SHOW_EDIT_FIELD';
export const SET_SHOW_MOST_FISKED = 'SET_SHOW_MOST_FISKED';
export const SET_SHOW_MOST_RECENT = 'SET_SHOW_MOST_RECENT';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const TOGGLE_FOCUSED = 'TOGGLE_FOCUSED';

export function createArticleSubmission(url): any {
  return httpFiskkitAction({
    method: 'POST',
    url: '/article-submissions',
    data: {
      url
    },
    type: CREATE_ARTICLE_SUBMISSION,
  });
}

export function fetchArticleSubmissionStatus(id): any {
  return httpFiskkitAction({
    method: 'GET',
    url: '/article-submissions/'+id+'/status',
    type: FETCH_ARTICLE_SUBMISSION_STATUS,
  });
}

export function fetchMostRecentArticles(offset?: number, url?: string): any {
  return httpFiskkitAction({
    method: 'GET',
    url: url || `/articles/most-recent?&offset=${offset || 0}`,
    data: null,
    type: FETCH_MOST_RECENT,
  });
}

export function fetchMostFiskedArticles(offset: number, url?: string): any {
  return httpFiskkitAction({
    method: 'GET',
    url: url || `/articles/most-fiskked?offset=${offset || 0}`,
    data: null,
    type: FETCH_MOST_FISKED,
  });
}

export function fetchArticlesInMyCourses(): any {
  return httpFiskkitAction({
    method: 'GET',
    url: '/user/articles',
    data: null,
    type: FETCH_ARTICLES_IN_MY_COURSES,
  });
}

export function resetArticleSubmission(offset) {
  return {
    type: RESET_ARTICLE_SUBMISSION,
  };
}

export function toggleFocused() {
  return {
    type: TOGGLE_FOCUSED,
  };
}

export function setActiveTab(payload: string) {
  return {
    payload,
    type: SET_ACTIVE_TAB,
  };
}

export function setShowMostRecent(payload: boolean) {
  return {
    payload,
    type: SET_SHOW_MOST_RECENT,
  };
}

export function setShowMostFisked(payload: boolean) {
  return {
    payload,
    type: SET_SHOW_MOST_FISKED,
  };
}

export function setShowEditField(payload: boolean) {
  return {
    payload,
    type: SET_SHOW_EDIT_FIELD,
  };
}

export function setArticleUrl(payload: string) {
  return {
    payload,
    type: SET_ARTICLE_URL,
  };
}

export function setCurrentUser(payload: {}) {
  return {
    payload,
    type: SET_CURRENT_USER,
  };
}

export function setCurrentUserCourses(payload: []) {
  return {
    payload,
    type: SET_CURRENT_USER_COURSES,
  };
}

export function setInputValue(payload: any) {
  return {
    payload,
    type: SET_INPUT_VALUE_HOMEPAGE,
  };
}

export function setNewCourse(payload: string) {
  return {
    payload,
    type: SET_NEW_COURSE,
  };
}

export function setAccountSettingsData(payload: {}) {
  return {
    payload,
    type: SET_ACCOUNT_SETTINGS_DATA,
  };
}

export function setAllCourses(payload: any) {
  return {
    payload,
    type: SET_ALL_COURSES,
  };
}

export function setCourseValue(payload: any) {
  return {
    payload,
    type: SET_COURSE_VALUE,
  };
}
