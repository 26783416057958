import _ from 'lodash';
import {SHOW_VIDEOS, CLOSE_VIDEOS } from '../actions';

const initialState = {show_videos: false};

export const videos = (state = initialState, action) => {
    let new_state;

    switch (action.type) {
          case SHOW_VIDEOS:
            let a = _.assignIn({}, state, action.payload);
            new_state = a;
          case CLOSE_VIDEOS:
            let b = _.assignIn({}, state, action.payload);
            new_state = b;

            break;

        default: new_state = state; break;
    }
    return new_state;
};
