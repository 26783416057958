import React from 'react';
import { Link } from 'react-router-dom';

import styles from './dropdown.module.scss';

const DropdownItem = (props) => {
  const { children, externalLink, linkTo, onClick, rel, target } = props

  return (
    linkTo ? (
      externalLink ? (
        <a href={linkTo} className={`${styles.menuItem} ${styles.menuLink}`} target={target || '_blank'} rel={rel || 'noopener'}>
          <li>{children}</li>
        </a>
      ) : (
        <Link to={linkTo} className={`${styles.menuItem} ${styles.menuLink}`}>
          <li>{children}</li>
        </Link>
    )) : (
      <li className={styles.menuItem} onClick={onClick}>
        {children}
      </li>
))}

export default DropdownItem;
