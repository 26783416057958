import React from 'react';
import styles from './carousel.module.scss';

const History = ({ changeSlide, current, itemCount }) => {
  return (
    <ul>
      {
        Array.from({length: itemCount}, (val, i)=>{
          return (
          <li key={`introVideo${i}`}>
            <div className={styles.container}>
              <div
                className={`${styles.circle} ${i === current ? styles.active : ''}`}
                onClick={ () => changeSlide(i) }
              ></div>
            </div>
          </li> 
        )})
      }
    </ul>
  )
}

export default History;
