import { httpFiskkitAction } from './util';
import { isNil, find, filter } from 'lodash';

export const CREATE_ARTICLE_COURSES = 'CREATE_ARTICLE_COURSES';
export const CREATE_ARTICLE_COURSES_FAILED = 'CREATE_ARTICLE_COURSES_FAILED';
export const CREATE_ARTICLE_COURSES_RECEIVED = 'CREATE_ARTICLE_COURSES_RECEIVED';
export const CREATE_ARTICLE_COURSES_REQUESTED = 'CREATE_ARTICLE_COURSES_REQUESTED';
export const CREATE_FISK = 'CREATE_FISK';
export const CREATE_FISK_FAILED = 'CREATE_FISK_FAILED';
export const CREATE_FISK_RECEIVED = 'CREATE_FISK_RECEIVED';
export const CREATE_FISK_REQUESTED = 'CREATE_FISK_REQUESTED';
export const DELETE_FISK = 'DELETE_FISK';
export const DELETE_FISK_FAILED = 'DELETE_FISK_FAILED';
export const DELETE_FISK_RECEIVED = 'DELETE_FISK_RECEIVED';
export const DELETE_FISK_REQUESTED = 'DELETE_FISK_REQUESTED';
export const CREATE_FISK_RESPECT = 'CREATE_FISK_RESPECT';
export const CREATE_FISK_RESPECT_FAILED = 'CREATE_FISK_RESPECT_FAILED';
export const CREATE_FISK_RESPECT_RECEIVED = 'CREATE_FISK_RESPECT_RECEIVED';
export const CREATE_FISK_RESPECT_REQUESTED = 'CREATE_FISK_RESPECT_REQUESTED';
export const DELETE_ARTICLE_COURSES = 'DELETE_ARTICLE_COURSES';
export const DELETE_ARTICLE_COURSES_FAILED = 'DELETE_ARTICLE_COURSES_FAILED';
export const DELETE_ARTICLE_COURSES_RECEIVED = 'DELETE_ARTICLE_COURSES_RECEIVED';
export const DELETE_ARTICLE_COURSES_REQUESTED = 'DELETE_ARTICLE_COURSES_REQUESTED';
export const DELETE_FISK_RESPECT = 'DELETE_FISK_RESPECT';
export const DELETE_FISK_RESPECT_FAILED = 'DELETE_FISK_RESPECT_FAILED';
export const DELETE_FISK_RESPECT_RECEIVED = 'DELETE_FISK_RESPECT_RECEIVED';
export const DELETE_FISK_RESPECT_REQUESTED = 'DELETE_FISK_RESPECT_REQUESTED';
export const DISCUSS_COLUMN_OPEN = 'DISCUSS_COLUMN_OPEN';
export const FETCH_ARTICLE = 'FETCH_ARTICLE';
export const FETCH_ARTICLE_COURSES = 'FETCH_ARTICLE_COURSES';
export const FETCH_ARTICLE_COURSES_FAILED = 'FETCH_ARTICLE_COURSES_FAILED';
export const FETCH_ARTICLE_COURSES_RECEIVED = 'FETCH_ARTICLE_COURSES_RECEIVED';
export const FETCH_ARTICLE_COURSES_REQUESTED = 'FETCH_ARTICLE_COURSES_REQUESTED';
export const FETCH_ARTICLE_CONTRIBUTORS = 'FETCH_ARTICLE_CONTRIBUTORS';
export const FETCH_ARTICLE_CONTRIBUTORS_FAILED = 'FETCH_ARTICLE_CONTRIBUTORS_FAILED';
export const FETCH_ARTICLE_CONTRIBUTORS_RECEIVED = 'FETCH_ARTICLE_CONTRIBUTORS_RECEIVED';
export const FETCH_ARTICLE_CONTRIBUTORS_REQUESTED = 'FETCH_ARTICLE_CONTRIBUTORS_REQUESTED';
export const FETCH_ARTICLE_FAILED = 'FETCH_ARTICLE_FAILED';
export const FETCH_ARTICLE_FISKS = 'FETCH_ARTICLE_FISKS';
export const FETCH_ARTICLE_FISKS_FAILED = 'FETCH_ARTICLE_FISKS_FAILED';
export const FETCH_ARTICLE_FISKS_RECEIVED = 'FETCH_ARTICLE_FISKS_RECEIVED';
export const FETCH_ARTICLE_FISKS_REQUESTED = 'FETCH_ARTICLE_FISKS_REQUESTED';
export const FETCH_ARTICLE_INSIGHTS = 'FETCH_ARTICLE_INSIGHTS';
export const FETCH_ARTICLE_INSIGHTS_FAILED = 'FETCH_ARTICLE_INSIGHTS_FAILED';
export const FETCH_ARTICLE_INSIGHTS_RECEIVED = 'FETCH_ARTICLE_INSIGHTS_RECEIVED';
export const FETCH_ARTICLE_INSIGHTS_REQUESTED = 'FETCH_ARTICLE_INSIGHTS_REQUESTED';
export const FETCH_ARTICLE_RECEIVED = 'FETCH_ARTICLE_RECEIVED';
export const FETCH_ARTICLE_REQUESTED = 'FETCH_ARTICLE_REQUESTED';
export const FETCH_FISK = 'FETCH_FISK';
export const FETCH_FISK_FAILED = 'FETCH_FISK_FAILED';
export const FETCH_FISK_RECEIVED = 'FETCH_FISK_RECEIVED';
export const FETCH_FISK_REQUESTED = 'FETCH_FISK_REQUESTED';
export const FETCH_FISK_CHILDREN = 'FETCH_FISK_CHILDREN';
export const FETCH_FISK_CHILDREN_FAILED = 'FETCH_FISK_CHILDREN_FAILED';
export const FETCH_FISK_CHILDREN_RECEIVED = 'FETCH_FISK_CHILDREN_RECEIVED';
export const FETCH_FISK_CHILDREN_REQUESTED = 'FETCH_FISK_CHILDREN_REQUESTED';
export const OPTIMISTICALLY_SAVE_FISK = 'OPTIMISTCALLY_SAVE_FISK';
export const OPTIMISTIC_FISK_CLEAR = 'OPTIMISTIC_FISK_CLEAR';
export const SAVE_FISK_DRAFT = 'SAVE_FISK_DRAFT';
export const SENTENCE_OPEN_ALL = 'SENTENCE_OPEN_ALL';
export const SENTENCE_CLOSE_ALL = 'SENTENCE_CLOSE_ALL';
export const SENTENCE_IS_OPEN = 'SENTENCE_IS_OPEN'; 
export const SET_ACTIVE_VALUES = 'SET_ACTIVE_VALUES';
export const SET_ISO_MODE = 'SET_ISO_MODE';
export const UPDATE_FISK = 'UPDATE_FISK';
export const UPDATE_FISK_FAILED = 'UPDATE_FISK_FAILED';
export const UPDATE_FISK_RECEIVED = 'UPDATE_FISK_RECEIVED';
export const UPDATE_FISK_REQUESTED = 'UPDATE_FISK_REQUESTED';
export const UPDATE_COURSE_ARTICLE = 'UPDATE_COURSE_ARTICLE';
export const UPDATE_COURSE_ARTICLE_FAILED = 'UPDATE_COURSE_ARTICLE_FAILED';
export const UPDATE_COURSE_ARTICLE_RECEIVED = 'UPDATE_COURSE_ARTICLE_RECEIVED';
export const UPDATE_COURSE_ARTICLE_REQUESTED = 'UPDATE_COURSE_ARTICLE_REQUESTED';

export const sentenceOpenAll = () => ({ type: SENTENCE_OPEN_ALL });
export const sentenceCloseAll = () => ({ type: SENTENCE_CLOSE_ALL });
export const sentenceIsOpen = (sentenceId: string, isOpen: boolean) => ({
  type: SENTENCE_IS_OPEN,
  payload: { sentenceId, isOpen },
});

export function optimisticallySaveFisk(save_type, fisk) {
  return {
    payload: {save_type, fisk},
    type: OPTIMISTICALLY_SAVE_FISK
  }
}

export const optimisticFiskClear = () => ({ type: OPTIMISTIC_FISK_CLEAR })

export function fetchDraft(fisk) {
  let draft;
  const draftsString = localStorage.getItem("drafts");
  const drafts = isNil(draftsString) ? { fisks: [] } : JSON.parse(draftsString); // localStorage can only store strings.
  if(!isNil(drafts.fisks) && drafts.fisks.length > 0){
      draft = find(drafts.fisks, function(draft) { 
        return (
          draft.article_id === fisk.article_id &&
          draft.parent_sentence_id === fisk.parent_sentence_id &&
          draft.parent_fisk_id === fisk.parent_fisk_id &&
          draft.user_id === fisk.user_id
        );
      });
    }
  if (!isNil(draft)) draft.draftLastModified = Date.now();
  return draft;
}

export function deleteFiskDraft(fisk) {
  const draftsString = localStorage.getItem("drafts");
  const drafts = (isNil(draftsString) || draftsString.length < 1) ? { fisks: [] } : JSON.parse(draftsString); // localStorage can only store strings.
  let filteredFisks = filter(drafts.fisks, function(draft) {
    return (
      draft.article_id !== fisk.article_id ||
      draft.parent_sentence_id !== fisk.parent_sentence_id ||
      draft.parent_fisk_id !== fisk.parent_fisk_id ||
      draft.user_id !== fisk.user_id 
    );
  });

  drafts.fisks = filteredFisks;
  localStorage.setItem("drafts", JSON.stringify(drafts));
}

export function saveFiskDraft(fisk) {
  const draftsString = localStorage.getItem("drafts");
  const drafts = (isNil(draftsString) || draftsString.length < 1) ? { fisks: [] } : JSON.parse(draftsString); // localStorage can only store strings.
  let filteredFisks = filter(drafts.fisks, function(draft) {
    return (
      draft.article_id !== fisk.article_id ||
      draft.parent_sentence_id !== fisk.parent_sentence_id ||
      draft.parent_fisk_id !== fisk.parent_fisk_id ||
      draft.user_id !== fisk.user_id 
    );
  });

  filteredFisks = filter(filteredFisks, function(draft) {
    const currentTime = Date.now();
    const draftLastModified = isNil(draft.draftLastModified) ? 0 : draft.draftLastModified;
    const draftAge = currentTime - draftLastModified;
    return millisToDays(draftAge) < 7;
  });

  fisk.draftLastModified = Date.now();
  filteredFisks.push(fisk);
  drafts.fisks = filteredFisks;
  localStorage.setItem("drafts", JSON.stringify(drafts));
  return {
    payload: {fisk},
    type: SAVE_FISK_DRAFT
  }
}

function millisToDays(m) {
  //        / to sec / to min / to hour / to day;
  return m / 1000 / 60 / 60 / 24;
}


export const discussColumnClose = (level: number) => ({
  payload: {level},
  type: DISCUSS_COLUMN_OPEN
})

export function discussColumnOpen(level, fiskId, sentenceId, open = false) {
  return (dispatch) => {
    if (fiskId) dispatch(fetchFiskChildren(fiskId));
    return dispatch({
      payload: { level, fiskId, sentenceId, open },
      type: DISCUSS_COLUMN_OPEN,
    })
  }
}

export function setActiveValues(sentence_id, column_level) {
  const payload = { active: {sentence_id, column_level}};
  return {
    payload,
    type: SET_ACTIVE_VALUES,
  }
}

export const setIsoMode = (mode:string | null = null, id:number = 0) => (dispatch: (arg: object)=>object) => {
  dispatch(setActiveValues(null, null));
  dispatch(discussColumnClose(0));

  if (id) {
    dispatch(sentenceOpenAll());
  } else {
    dispatch(sentenceCloseAll());
  }

  return dispatch({
    payload: { 
      userId: mode === 'user' ? id : 0,
      courseId: mode === 'course' ? id : 0
    },
    type: SET_ISO_MODE,
  });
}

export function fetchFisk(fiskId) {
  return httpFiskkitAction({
    method: 'GET',
    url: `/fisks/${fiskId}`,
    type: FETCH_FISK,
  })
}

export function createFisk(data): any {
  return httpFiskkitAction({
    method: 'POST',
    url: '/fisks',
    data: data,
    type: CREATE_FISK,
  });
}

export function updateFisk(data): any {
  return httpFiskkitAction({
    method: 'PUT',
    url: '/fisks/'+data.id,
    data: data,
    type: UPDATE_FISK,
  });
}

export function updateCourseArticle(data): any {
  return httpFiskkitAction({
    method: 'PUT',
    url: '/course-articles/'+data.id,
    data: data,
    type: UPDATE_COURSE_ARTICLE,
    successMessage: "Article Settings Updated"
  });
}

export function deleteFisk(data): any {
  return httpFiskkitAction({
    method: 'DELETE',
    url: '/fisks/'+data.id,
    data: data,
    type: DELETE_FISK,
  });
}

export function createFiskRespect(fiskId:string): any {
  return httpFiskkitAction({
    method: 'POST',
    url: '/fisks/' + fiskId + '/respects',
    type: CREATE_FISK_RESPECT,
  });
}

export function deleteFiskRespect(fiskId:string): any {
  return httpFiskkitAction({
    method: 'DELETE',
    url: '/fisks/' + fiskId + '/respects',
    type: DELETE_FISK_RESPECT,
  });
}

export function fetchArticle(articleId:string): any {
  return httpFiskkitAction({
    method: 'GET',
    url: '/articles/' + articleId,
    type: FETCH_ARTICLE,
  });
}

export function createArticleCourses(coursesToAdd:any): any {
  return httpFiskkitAction({
    method: 'POST',
    url: '/course/addarticle',
    data: coursesToAdd,
    type: CREATE_ARTICLE_COURSES,
    successMessage: "Added Article to Course"
  });
}

export function deleteArticleCourses(coursesToRemove:any): any {
  return httpFiskkitAction({
    method: 'POST',
    url: '/course/removearticle',
    data: coursesToRemove,
    type: DELETE_ARTICLE_COURSES,
    successMessage: "Removed Article from Course"
  });
}

export function fetchArticleCourses(articleId:string): any {
  return httpFiskkitAction({
    method: 'GET',
    url: '/articles/' + articleId + '/courses',
    type: FETCH_ARTICLE_COURSES,
  });
}

export function fetchArticleContributors(articleId:string): any {
  return httpFiskkitAction({
    method: 'GET',
    url: '/articles/' + articleId + '/contributors',
    type: FETCH_ARTICLE_CONTRIBUTORS,
  });
}

export function fetchArticleFisks(articleId:string): any {
  return httpFiskkitAction({
    method: 'GET',
    url: '/articles/' + articleId + '/fisks',
    type: FETCH_ARTICLE_FISKS,
  });
}

export function fetchFiskChildren(parent_fisk_id:string): any {
  return httpFiskkitAction({
    method: 'GET',
    url: '/fisks/' + parent_fisk_id + '/fisks',
    type: FETCH_FISK_CHILDREN,
  })
}

export function fetchArticleInsights(articleId:string): any {
  return httpFiskkitAction({
    method: 'GET',
    url: '/articles/' + articleId + '/insights',
    type: FETCH_ARTICLE_INSIGHTS,
  });
}
