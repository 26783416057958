import React from 'react';

const PaypalButton = (props) => (
    <React.Fragment>
      <button form="paypal" style={{...resetButtonStyles, ...props.style}} border="0" type="submit" name="submit" title="PayPal - The safer, easier way to pay online!">{props.children}</button>
      {/* form is hiddden */}
      <form id="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank" style={{display: 'none'}}>
        <input type="hidden" name="cmd" value="_donations" />
        <input type="hidden" name="business" value="john@fiskkit.com" />
        <input type="hidden" name="item_name" value="Fiskkit is an unfunded social venture. Donations to Fiskkit are not tax deductible, but are helpful to sustain development." styles="height: 68px;" />
        <input type="hidden" name="currency_code" value="USD" />
        <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
      </form>
    </React.Fragment>
)

export default PaypalButton

const resetButtonStyles = {
  border: 'none',
  padding: 0,
  backgroundColor: 'transparent',
  textAlign: 'left',
  fontStyle: 'normal',
  fontSize: '100%',
  color: 'inherit',
  outline:' none',
}
