interface IAppState {
  API_HOST: string;
  APP_EXPERIENCE: string;
}

const initialState = {
  API_HOST: '',
  APP_EXPERIENCE: '',
};

export const app = (state = initialState, action): IAppState => {
  switch (action.type) {
    default:
      return state;
  }
};
