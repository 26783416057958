import _ from 'lodash';

import { LOGIN_REQUESTED, LOGIN_FAILED, SET_POST_LOGIN_REDIRECT } from '../actions';

const initialState = {
  error_description: null,
};

export const login = (state = initialState, action) => {
  switch (action.type) {

    case SET_POST_LOGIN_REDIRECT:
      return _.assignIn({}, state, action.payload);

    case LOGIN_REQUESTED:
      return _.assignIn({}, state, initialState);
    
    case LOGIN_FAILED:
      return _.assignIn({}, state, action.error);

    default:
      return state;
  }
};
