import _ from 'lodash';
import { FETCH_INSTRUCTORS_RECEIVED } from '../actions/instructors';

const initialState = [];

export const instructors = (state = initialState, action) => {
    let new_state;
    
    switch (action.type) {
        case FETCH_INSTRUCTORS_RECEIVED: new_state = _.sortBy(_.unionBy(state, action.payload.instructors, 'user_id'), 'name'); break;
        default: new_state = state; break;
    }
    
    return new_state;
};
