import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { logout } from '../../../actions/auth';

class Logout extends React.Component {
  onLogoutRedirectUrl = '/login';

  componentDidMount() {
    this.props.dispatch(logout());
  }

  render() {
    const { access_token } = this.props.auth;

    if (!access_token) {
      return <Redirect to={this.onLogoutRedirectUrl} />;
    }
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
}

export default connect(mapStateToProps)(Logout);
