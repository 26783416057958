import { httpFiskkitAction } from "./util";

export const FETCH_INSTRUCTORS = 'FETCH_INSTRUCTORS';
export const FETCH_INSTRUCTORS_FAILED = 'FETCH_INSTRUCTORS_FAILED';
export const FETCH_INSTRUCTORS_RECEIVED = 'FETCH_INSTRUCTORS_RECEIVED';
export const FETCH_INSTRUCTORS_REQUESTED = 'FETCH_INSTRUCTORS_REQUESTED';

export function fetchInstructors() {
    return httpFiskkitAction({
        method: 'GET',
        url: '/users/instructors', 
        type: FETCH_INSTRUCTORS, 
    });
}