import React, { Component } from 'react';
import _ from 'lodash';
import ProfilePicture from '../../../etc/profile-picture';
import styles from './style.module.scss';

export default class UserListItem extends Component
{
    render() {
        const { user, isOnToolbarDisplay } = this.props;

        if (!user) return null;

        return (
            <div className={styles.userItem}>
                <div className={`${styles.userPicture} ${isOnToolbarDisplay ? styles.toolbarUserPicture: ''}`}>
                    <ProfilePicture user={user}/>
                </div>
                <div className={`${styles.userName} ${isOnToolbarDisplay ? styles.toolbarUserName: ''}`}>
                    {user.name}
                </div>
            </div>
        )
    }
}
