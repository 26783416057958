export function loading (state = {}, action) {
    if (action.type.endsWith('_REQUESTED')) {
        const requestName = action.type.replace('_REQUESTED', '');
        const counter = state[requestName] || 0;
        return { ...state, [requestName]: counter + 1};
    }

    if (action.type.endsWith('_RECEIVED') || action.type.endsWith('_FAILED')) {
        const requestName = action.type.replace('_RECEIVED', '').replace('_FAILED', '');
        const counter = state[requestName] || 0;
        return { ...state, [requestName]: counter - 1};
    }

    return state;
}