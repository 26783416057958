import React from 'react';
import ReactPlayer from 'react-player';
import style from './player.module.scss';

//RESPONSIVE
const Player = (props) => {
    return (
      <div className={style.playerWrapper}>
        <ReactPlayer
          className={style.reactPlayer}
          url={props.url}
          width='100%'
          height='100%'
          controls={true}
          playbackRate={0.5}
        />
      </div>
    )
}
export default Player;
