import React from 'react';

import Icon from '../../../icon';
import styles from './tabMission.module.scss';

const TabMission = () => (
  <div className={styles.missionContainer}>
    <div id="Slide2" className="tabcontent" style={{ display: 'block' }}>
      <div className={styles.postContainer}>
        <div className={styles.postContent}>
          <div className={styles.postTitle}>
            {/*<h1>What is Fiskkit?</h1>*/}
          </div>
          <div className={styles.postParagraph}>
            {/* tslint:disable-next-line:max-line-length */}
            <p> "Fisk" to refute or criticize (a journalistic article or blog) point by point.</p>
          </div>
          <div className={styles.postParagraph}>
            {/* tslint:disable-next-line:max-line-length */}
            <p> At Fiskkit, our mission is to facilitate better online discussions so that they are more respectful, engaging, and fruitful. We encourage you to get involved in the discussion and help foster an online community based on facts and logic.</p>
          </div>
        </div>
      </div>

      <div className={styles.parent}>
        <div className={styles.child}>
          <Icon name="discussion" width={150} height={117} fill="black" />
        </div>
        <div className={styles.child}>
          <Icon name="connectedness" width={150} height={117} fill="black" />
        </div>
        <div className={styles.child}>
          <Icon name="comments" width={150} height={117} fill="black" />
        </div>
      </div>
    </div>
  </div>
);


export default TabMission;
