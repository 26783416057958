import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Layout from './components/layout';
import OnlyClassroom from './components/layout/only-classroom';
import OnlyInstructors from './components/layout/only-instructors';
import Logout from './components/pages/logout';
import GA from './utils/GoogleAnalytics';

import skeleton from './resources/img/skeleton-loading.png';
import OpEdBanner from './components/etc/op-ed-banner';

// Lazy loaded components for code splitting
const Home = lazy(()=>import('./components/pages/home'));
const Article = lazy(()=>import('./components/pages/discuss/article'));
const UserSettings = lazy(()=>import('./components/pages/user-settings'));
const AuthDialog = lazy(()=>import('./components/layout/auth-dialog'));
const Login = lazy(()=>import('./components/pages/login'));
const Signup = lazy(()=>import('./components/pages/signup'));
const Reset = lazy(()=>import('./components/pages/reset'));
const EmailConfirm = lazy(()=>import('./components/pages/email/confirm'))
const ClassInsights = lazy(()=>import('./components/pages/user-insight'))

function App() {
  return (
    <Router>  
      { GA.init() && <GA.RouteTracker /> }
      <Layout>
        <Suspense
          fallback={
            <div className="skeleton-blink">
              <img src={skeleton} style={{width: '100vw', height: '100vh'}} alt="skeleton" />
            </div>
          }
        >

          {/* display banner on every page except article pages since they
           have dynamic columns and thus have weird scrolling mechanism.
           Banner is attached to ArticleHeader instead */}
          <Route path={/^((?!article|iso|discuss|insights).)*$/} component={OpEdBanner} />

          <Route exact path='/' component={Home} />
          <Route path='/oped' component={() => {
            window.location.href = 'https://oped.fiskkit.com/signup';
            return null;
          }} />
          <Route path='/article/:article_id' component={Article} />
          <Switch>
            <Route path='/user/settings' component={UserSettings} />
            <OnlyClassroom>
              <OnlyInstructors>
                <Route path='/user/classinsights' component={ClassInsights} />
              </OnlyInstructors>
            </OnlyClassroom>
          </Switch>

          <Route path={staticPageRoutes.map(route=>route.path)}>
            {staticPageRoutes.map((route)=><Route key={route.path} path={route.path} component={route.component}/>)}
          </Route>

          <OnlyClassroom>
            <Route path='/email/confirm/:token' component={EmailConfirm} />
            <Route path={['/login','/signup', '/reset']}>
              <AuthDialog>
                <Suspense fallback={''}>
                  <Route path='/login' component={Login} />
                  <Route path='/signup' component={Signup} />
                  <Route path='/reset/link/:link' component={Reset} />
                </Suspense>
              </AuthDialog>
            </Route>
            <Route path='/logout' component={Logout} />
          </OnlyClassroom>
        </Suspense>
      </Layout>
    </Router>
  )
}

export default App;

const staticPageRoutes = [
{
  path: '/about',
  component: lazy(() => import('./components/pages/about')),
},
{
  path: '/organizations',
  component: lazy(() => import('./components/pages/organizations')),
},
{
  path: '/faq',
  component: lazy(() => import('./components/pages/faq')),
},
{
  path: '/terms',
  component: lazy(() => import('./components/pages/terms')),
},
{
  path: '/team',
  component: lazy(() => import('./components/pages/team')),
},
{
  path: '/email/sent',
  component: lazy(() => import('./components/pages/email/sent')),
},
{
  path: '/privacy',
  component: lazy(() => import('./components/pages/privacy')),
},

];
