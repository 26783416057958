// This initilizes GA and tracks page changes
import React, { Component } from 'react'
import ReactGA from 'react-ga'
import { Route } from 'react-router-dom'

// Component
class GoogleAnalytics extends Component {
  componentDidMount() {
    this.logPageChange(this.props.location.pathname)
  }

  componentDidUpdate({ location: prevLocation }) {
    const { location: { pathname } } = this.props;
    const isDifferentPathname = pathname !== prevLocation.pathname;

    if (isDifferentPathname) {
      this.logPageChange(pathname);
    }
  }

  logPageChange(pathname) {
    let pageNamesWithIds = ['discuss', 'iso', 'insights'];
    
    // record generic page views if pathname contains an ID
    if (pageNamesWithIds.some(el => pathname.includes(el))) {
      let pathArr = pathname.split('/');
      let noIdPathName;

      if (pathname.includes('iso')) {
        noIdPathName = pathArr.slice(0, 2).concat(pathArr.slice(3, 4)).join('/');
      } else {
        noIdPathName = pathArr.slice(0, 2).concat(pathArr.slice(3)).join('/');
      }

      ReactGA.pageview(noIdPathName);
    }

    ReactGA.pageview(pathname);
  }

  render() {
    return null;
  }
}

/// Methods
const RouteTracker = () => <Route component={GoogleAnalytics} />

const init = () => {
  const isGAEnabled = !!process.env.REACT_APP_GA_TRACKING_ID;

  if (isGAEnabled) {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
  }

  return isGAEnabled;
}

export default {
  GoogleAnalytics,
  RouteTracker,
  init
}

// Reference: https://vanja.gavric.org/blog/integrate-google-analytics-with-react-router-v4/
// (simplified to not include 'search' and 'options')
