export const SHOW_VIDEOS = 'SHOW_VIDEOS';
export const CLOSE_VIDEOS = 'CLOSE_VIDEOS';

export const showVideos = () => ({
    type: SHOW_VIDEOS,
    payload: {show_videos: true}
});
export const closeVideos = () => ({
    type: CLOSE_VIDEOS,
    payload: {show_videos: false}
});
