export { TAGS } from './tags'

export const EXP_CLASSROOM = 'classroom';
export const EXP_CORE = 'core';

export const MODAL_ADD_ARTICLE_TO_COURSE = 'MODAL_ADD_ARTICLE_TO_COURSE';
export const MODAL_LOGIN = 'MODAL_LOGIN';
export const MODAL_FORGOT_PASSWORD = 'MODAL_FORGOT_PASSWORD';
export const MODAL_SEND_CONFIRMATION_EMAIL = 'MODAL_SEND_CONFIRMATION_EMAIL';
export const MODAL_WHAT_IS_FISKKIT = 'MODAL_WHAT_IS_FISKKIT';
export const MODAL_TOOLBAR_OPTIONS = "MODAL_TOOLBAR_OPTIONS";

export const FACEBOOK_APP_ID = '1190978887652851';
export const FACEBOOK_APP_ID_DEV = '547770259372677';
