import { logout } from './auth';

export const HTTP_ACTION = 'HTTP_ACTION';

export function httpFiskkitAction(options) {

  const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  const tokenIsValid = (token, dispatch, getAppState, options) => {
      const token_expiration = parseJwt(token).exp;
      if (Date.now() > token_expiration * 1000) {
          // invalid token
          if(options.type === "CREATE_FISK" || options.type === "UPDATE_FISK" || options.type === "FETCH_FISK_CHILDREN"){
             if(options.data){
                //retrieve from localStorage
                options.data.user = getAppState().user;
             }else{
               //retrieve from redux
               if(getAppState().discuss.fiskDraft){
                  const data = getAppState().discuss.fiskDraft.fisk;
                  data.user = getAppState().user;
               }
             }
          }
          return dispatch(logout());
      }
  }

  return (dispatch, getState) => {

      const { API_HOST } = getState().app;
      const { access_token } = getState().auth;
      let { headers, method, url } = options;

      if (access_token) tokenIsValid(access_token, dispatch, getState, options)

      method = method || 'GET';
      url = API_HOST + '/api/v1' + url;
      headers = Object.assign({
        'Authorization': `Bearer ${access_token}`,
        'Content-Type': 'application/json',
        // crossorigin: true,
      }, headers);

      return dispatch(httpAction({...options, method, url, headers})).then(() =>{

        //if credentials reestablished and localStorage item available: delete draft
        if(getState().auth.access_token !== "null" && getState().auth.access_token !== null && getState().auth.access_token != null && localStorage.getItem("draft")){
        }else{
          //DEBUG
        }
      }).catch((error)=>{
        //.log("ERROR 401")
        //console.log(error)
      });
  }
}

export function httpAction(options) {
  const defaults = {
    headers: {
      'Content-Type': 'application/json',
    }
  };

  return {
    HTTP_ACTION: Object.assign({}, defaults, options),
  };
}
