import _ from 'lodash';

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
}; 

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch {
    // ignore write errors
  }
  return state;
};

export const persist = (reducer, state) => {
  try {
    localStorage.setItem('state', JSON.stringify(_.assignIn({}, loadState(), {[reducer]: state})));
  } catch {
    // ignore write errors
  }
  return state;
};

// export function getFiskTagIdsRecursive(parent_id, allChildFisksDataArray, currentUser): any {
//   return allChildFisksDataArray.filter((item) => {
//     return (item.parent_fisk_id === parent_id);
//   })
//     .find((comment) => {
//       return comment.user_id === currentUser.id;
//     })
//     && allChildFisksDataArray.data.filter((item) => {
//       // tslint:disable-next-line:max-line-length
//       return (item.parent_fisk_id === parent_id);
//     })
//       .find((comment) => {
//         return comment.user_id === currentUser.id;
//       }).fisk_tags.map((tagObj) => {
//         return tagObj.tag_id;
//       })
// }

// export function getCurrentFiskFiskIdRecursive(parent_id, allChildFisksDataArray, currentUser): number {
//   return allChildFisksDataArray.filter((item) => {
//     // tslint:disable-next-line:max-line-length
//     return (item.parent_fisk_id === parent_id);
//   })
//     .find((comment) => {
//       return comment.user_id === currentUser.id;
//     })
//     && allChildFisksDataArray.filter((item) => {
//       // tslint:disable-next-line:max-line-length
//       return (item.parent_fisk_id === parent_id);
//     })
//       .find((comment) => {
//         return comment.user_id === currentUser.id;
//       }).id
// }

// export function getCurrentCommentFiskId(dataArray, clickedSentenceId, currentUser): number {
//   return dataArray.filter((item) => {
//     // tslint:disable-next-line:max-line-length
//     return (item.parent_sentence_id === parseInt(clickedSentenceId, 10));
//   })
//     .find((comment) => {
//       return comment.user_id === currentUser.id;
//     })
//     && dataArray.filter((item) => {
//       // tslint:disable-next-line:max-line-length
//       return (item.parent_sentence_id === parseInt(clickedSentenceId, 10));
//     })
//       .find((comment) => {
//         return comment.user_id === currentUser.id;
//       }).id
// }

// export function getCurrentCommentFiskIdRecursive(dataArray, clickedSentenceId, currentUser): number {
//   return dataArray.filter((item) => {
//     // tslint:disable-next-line:max-line-length
//     return (item.parent_fisk_id === clickedSentenceId);
//   })
//     .find((comment) => {
//       return comment.user_id === currentUser.id;
//     })
//     && dataArray.filter((item) => {
//       // tslint:disable-next-line:max-line-length
//       return (item.parent_fisk_id === clickedSentenceId);
//     })
//       .find((comment) => {
//         return comment.user_id === currentUser.id;
//       }).id
// }

// export function getCurrentCommentTagIdsRecursive(dataArray, clickedSentenceId, currentUser): number {
//   return dataArray.filter((item) => {
//     // tslint:disable-next-line:max-line-length
//     return (item.parent_fisk_id === clickedSentenceId);
//   })
//     .find((comment) => {
//       return comment.user_id === currentUser.id;
//     })
//     && dataArray.filter((item) => {
//       // tslint:disable-next-line:max-line-length
//       return (item.parent_fisk_id === clickedSentenceId);
//     })
//       .find((comment) => {
//         console.log('comment -------------getCurrentCommentTagIdsRecursive : ', comment)
//         return comment.user_id === currentUser.id;
//       }).tag_ids
// }

// export function getCurrentFiskFiskTagsRecursive(column, allChildFisksDataArray, currentUser): any {
//   return allChildFisksDataArray && allChildFisksDataArray.filter((item) => {
//     return (item.parent_fisk_id === column.id)
//   }).length > 0
//     ?
//     allChildFisksDataArray.filter((item) => {
//       return (item.parent_fisk_id === column.id)
//     })
//       .find((childComment) => {
//         return (childComment.user_id === currentUser.id)
//       })
//       ?
//       allChildFisksDataArray.filter((item) => {
//         return (item.parent_fisk_id === column.id)
//       })
//         .find((childComment) => {
//           return (childComment.user_id === currentUser.id)
//         }).fisk_tags
//       :
//       []
//     :
//     []
// }

// export function getCurretnFiskSentencesRecursive(column, allChildFisksDataArray, currentUser): any {
//   return allChildFisksDataArray && allChildFisksDataArray.filter((item) => {
//     return (item.parent_fisk_id === column.id)
//   }).length > 0
//     ?
//     allChildFisksDataArray.filter((item) => {
//       return (item.parent_fisk_id === column.id)
//     })
//       .find((childComment) => {
//         return (childComment.user_id === currentUser.id)
//       })
//       ?
//       allChildFisksDataArray.filter((item) => {
//         return (item.parent_fisk_id === column.id)
//       })
//         .find((childComment) => {
//           return (childComment.user_id === currentUser.id)
//         }).sentences
//       :
//       []
//     :
//     []
// }

// export function getCurrentCommentFiskTags(allArticleFisksData, clickedSentenceId, currentUser): any {
//   return allArticleFisksData && allArticleFisksData.data.filter((item) => {
//     return (item.parent_sentence_id === parseInt(clickedSentenceId, 10))
//   })
//     ?
//     allArticleFisksData.data.filter((item) => {
//       return (item.parent_sentence_id === parseInt(clickedSentenceId, 10))
//     })
//       .find((comment) => {
//         return (comment.user_id === currentUser.id)
//       })
//       ?
//       allArticleFisksData.data.filter((item) => {
//         return (item.parent_sentence_id === parseInt(clickedSentenceId, 10))
//       })
//         .find((comment) => {
//           return (comment.user_id === currentUser.id)
//         }).fisk_tags
//       :
//       []
//     :
//     []
// }

// export function getCurrentCommentFiskTagsRecursive(allChildFisksDataArray, column, currentUser): any {
//   return allChildFisksDataArray && allChildFisksDataArray.filter((item) => {
//     return (item.parent_fisk_id === column.id)
//   }).length > 0
//     ?
//     allChildFisksDataArray.filter((item) => {
//       return (item.parent_fisk_id === column.id)
//     })
//       .find((childComment) => {
//         return (childComment.user_id === currentUser.id)
//       })
//       ?
//       allChildFisksDataArray.filter((item) => {
//         return (item.parent_fisk_id === column.id)
//       })
//         .find((childComment) => {
//           return (childComment.user_id === currentUser.id)
//         }).fisk_tags
//       :
//       []
//     :
//     []
// }

// export function getCurrentCommentSentences(allArticleFisksData, clickedSentenceId, currentUser): any {
//   return allArticleFisksData && allArticleFisksData.data.filter((item) => {
//     return (item.parent_sentence_id === parseInt(clickedSentenceId, 10))
//   })
//     ?
//     allArticleFisksData.data.filter((item) => {
//       return (item.parent_sentence_id === parseInt(clickedSentenceId, 10))
//     })
//       .find((comment) => {
//         return (comment.user_id === currentUser.id)
//       })
//       ?
//       allArticleFisksData.data.filter((item) => {
//         return (item.parent_sentence_id === parseInt(clickedSentenceId, 10))
//       })
//         .find((comment) => {
//           return (comment.user_id === currentUser.id)
//         }).sentences
//       :
//       []
//     :
//     []
// }

// export function getParentSentenceId(allArticleFisksData, clickedSentenceId, currentUser): number {
//   return allArticleFisksData.data.filter((item) => {
//     // tslint:disable-next-line:max-line-length
//     return (item.parent_sentence_id === parseInt(clickedSentenceId, 10));
//   })
//     .find((comment) => {
//       return comment.user_id === currentUser.id;
//     })
//     && allArticleFisksData.data.filter((item) => {
//       // tslint:disable-next-line:max-line-length
//       return (item.parent_sentence_id === parseInt(clickedSentenceId, 10));
//     })
//       .find((comment) => {
//         return comment.user_id === currentUser.id;
//       }).parent_sentence_id || parseInt(clickedSentenceId, 10);
// }

// export function getParentSentenceIdRecursive(allChildFisksDataArray, column, currentUser): number {
//   return allChildFisksDataArray.filter((item) => {
//     // tslint:disable-next-line:max-line-length
//     return (item.parent_fisk_id === column.id);
//   })
//     .find((comment) => {
//       return comment.user_id === currentUser.id;
//     })
//     && allChildFisksDataArray.filter((item) => {
//       // tslint:disable-next-line:max-line-length
//       return (item.parent_fisk_id === column.id);
//     })
//       .find((comment) => {
//         return comment.user_id === currentUser.id;
//       }).parent_sentence_id || column.id;
// }

// export function getParentFiskId(allArticleFisksData, clickedSentenceId, currentUser): number {
//   return allArticleFisksData.data.filter((item) => {
//     // tslint:disable-next-line:max-line-length
//     return (item.parent_sentence_id === parseInt(clickedSentenceId, 10));
//   })
//     .find((comment) => {
//       return comment.user_id === currentUser.id;
//     })
//     && allArticleFisksData.data.filter((item) => {
//       return (item.parent_sentence_id === parseInt(clickedSentenceId, 10));
//     })
//       .find((comment) => {
//         return comment.user_id === currentUser.id;
//       }).parent_fisk_id || null;
// }

// export function getParentFiskIdRecursive(allChildFisksDataArray, column, currentUser): number {
//   return allChildFisksDataArray.filter((item) => {
//     return (item.parent_fisk_id === column.id);
//   })
//     .find((comment) => {
//       return comment.user_id === currentUser.id;
//     })
//     && allChildFisksDataArray.filter((item) => {
//       return (item.parent_fisk_id === column.id);
//     })
//       .find((comment) => {
//         return comment.user_id === currentUser.id;
//       }).parent_fisk_id || null;
// }

// // current comment object
// export function currentCommentData(allArticleFisksData, clickedSentenceId, currentUser): any {
//   return allArticleFisksData.data.filter((item) => {
//     return (item.parent_sentence_id === parseInt(clickedSentenceId, 10));
//   })
//     .find((comment) => {
//       return comment.user_id === currentUser.id;

//     });
// }

// // check the backend - if it has a comment to
// // current sentence from current user
// export function isCommentExists(dataArray, clickedSentenceId, currentUser): boolean {
//   return dataArray.filter((item) => {
//     // tslint:disable-next-line:max-line-length
//     return (item.parent_sentence_id === parseInt(clickedSentenceId, 10));
//   })
//     .find((comment) => {
//       return comment.user_id === currentUser.id;

//     }) !== undefined;
// }

// export function isCommentExistsRecursive(dataArray, clickedSentenceId, currentUser): boolean {
//   return dataArray.filter((item) => {
//     // tslint:disable-next-line:max-line-length
//     return (item.parent_fisk_id === clickedSentenceId);
//   })
//     .find((comment) => {
//       return comment.user_id === currentUser.id;

//     }) !== undefined;
// }

// export function isRecursiveFiskExists(allChildFisksDataArray, clickedSentenceId, currentUser): boolean {
//   return allChildFisksDataArray.filter((item) => {
//     // tslint:disable-next-line:max-line-length
//     return (item.parent_sentence_id === parseInt(clickedSentenceId, 10));
//   })
//     .find((comment) => {
//       return comment.user_id === currentUser.id;
//     }) !== undefined;
// }

// export function getTagId(tagName: string): number {
//   let tag_id;
//   switch (tagName) {
//     case 'True':
//       tag_id = 1;
//       break;
//     case 'False':
//       tag_id = 2;
//       break;
//     case 'Matter of Opinion':
//       tag_id = 3;
//       break;
//     case 'Overgeneralization':
//       tag_id = 4;
//       break;
//     case 'Cherry Picking':
//       tag_id = 5;
//       break;
//     case 'Straw Man':
//       tag_id = 6;
//       break;
//     case 'Insightful':
//       tag_id = 7;
//       break;
//     case 'Well Researched':
//       tag_id = 8;
//       break;
//     case 'Funny':
//       tag_id = 9;
//       break;
//     case 'Unsupported':
//       tag_id = 10;
//       break;
//     case 'Overly Simplistic':
//       tag_id = 11;
//       break;
//     case 'Biased Wording':
//       tag_id = 12;
//       break;

//     case 'Provably True':
//       tag_id = 13;
//       break;
//     case 'Provably False':
//       tag_id = 14;
//       break;
//     case 'Personal Attack':
//       tag_id = 15;
//       break;
//     case 'Off-Topic':
//       tag_id = 16;
//       break;
//     case 'Concession':
//       tag_id = 17;
//       break;

//     default:
//       tag_id = 0;
//   }
//   return tag_id;
// }

// export function getTagName(tagId: number): string {
//   let name;
//   switch (tagId) {
//     case 1:
//       name = 'True';
//       break;
//     case 2:
//       name = 'False';
//       break;
//     case 3:
//       name = 'Matter of Opinion';
//       break;
//     case 4:
//       name = 'Overgeneralization';
//       break;
//     case 5:
//       name = 'Cherry Picking';
//       break;
//     case 6:
//       name = 'Straw Man';
//       break;
//     case 7:
//       name = 'Insightful';
//       break;
//     case 8:
//       name = 'Well Researched';
//       break;
//     case 9:
//       name = 'Funny';
//       break;
//     case 10:
//       name = 'Unsupported';
//       break;
//     case 11:
//       name = 'Overly Simplistic';
//       break;
//     case 12:
//       name = 'Biased Wording';
//       break;

//     case 13:
//       name = 'Provably True';
//       break;
//     case 14:
//       name = 'Provably False';
//       break;
//     case 15:
//       name = 'Personal Attack';
//       break;
//     case 16:
//       name = 'Off-Topic';
//       break;
//     case 17:
//       name = 'Concession';
//       break;

//     default:
//       name = '';
//   }

//   return name;
// }

// export function getTagColor(tagId: number): string {
//   let tagColor;
//   switch (tagId) {

//     case 1:
//     case 2:
//     case 3:
//     case 13:
//     case 14:
//       tagColor = 'rgb(120, 87, 207)';
//       break;

//     case 4:
//     case 5:
//     case 6:
//       tagColor = '#CA5339';
//       break;

//     case 7:
//     case 8:
//     case 9:
//     case 17:
//       tagColor = '#00C87B';
//       break;

//     case 10:
//     case 11:
//     case 12:
//     case 15:
//     case 16:
//       tagColor = '#3078A7';
//       break;

//     default:
//       tagColor = 'lightgrey';
//   }

//   return tagColor;
// }

export function isOdd(index) {
  return index % 2;
}

// export function getTagNameFromResponse(tagId: number): string {
//   let name;
//   switch (tagId) {
//     case 1:
//       name = 'True';
//       break;
//     case 2:
//       name = 'False';
//       break;
//     case 3:
//       name = 'Matter of Opinion';
//       break;
//     case 4:
//       name = 'Overgeneralization';
//       break;
//     case 5:
//       name = 'Cherry Picking';
//       break;
//     case 6:
//       name = 'Straw Man';
//       break;
//     case 7:
//       name = 'Unsupported';
//       break;
//     case 8:
//       name = 'Overly Simplistic';
//       break;
//     case 9:
//       name = 'Biased Wording';
//       break;
//     case 10:
//       name = 'Insightful';
//       break;
//     case 11:
//       name = 'Well Researched';
//       break;
//     case 12:
//       name = 'Funny';
//       break;

//     case 13:
//       name = 'Provably True';
//       break;
//     case 14:
//       name = 'Provably False';
//       break;
//     case 15:
//       name = 'Personal Attack';
//       break;
//     case 16:
//       name = 'Off-Topic';
//       break;
//     case 17:
//       name = 'Concession';
//       break;

//     default:
//       name = '';
//   }

//   return name;
// }

// export function getTagColorFromResponse(tagId: number): string {
//   let tagColor;
//   switch (tagId) {

//     case 1:
//     case 2:
//     case 3:
//     case 13:
//     case 14:
//       tagColor = 'rgb(120, 87, 207)';
//       break;

//     case 4:
//     case 5:
//     case 6:
//       tagColor = '#CA5339';
//       break;

//     case 7:
//     case 8:
//     case 9:
//     case 15:
//     case 16:
//       tagColor = '#3078A7';
//       break;

//     case 10:
//     case 11:
//     case 12:
//     case 17:
//       tagColor = '#00C87B';
//       break;

//     default:
//       tagColor = 'lightgrey';
//   }

//   return tagColor;
// }

export function containsURL(text: string, urlRegex: any): any {
  const blackList = [
    'http://www.google.com',
    'https://www.google.com',
    'www.google.com',
    'google.com',
    'http://www.yandex.ru',
    'http://www.bbc.uk'
  ];
  const isUrlInBlackList = blackList.find((url) => {
    return (
      text.includes(url)
      );
    })
    return (text.match(urlRegex) !== null && isUrlInBlackList === undefined);
  }
  