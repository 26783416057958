import { httpAction } from './util';

export const SET_POST_LOGIN_REDIRECT = 'SET_POST_LOGIN_REDIRECT';
export const SIGNUP = 'SIGNUP';
export const SIGNUP_REQUESTED = 'SIGNUP_REQUESTED';
export const SIGNUP_RECEIVED = 'SIGNUP_RECEIVED';
export const SIGNUP_FAILED = 'SIGNUP_FAILED';
export const SEND_EMAIL_CONFIRMATION = 'SEND_EMAIL_CONFIRMATION';
export const SEND_EMAIL_CONFIRMATION_REQUESTED = 'SEND_EMAIL_CONFIRMATION_REQUESTED';
export const SEND_EMAIL_CONFIRMATION_RECEIVED = 'SEND_EMAIL_CONFIRMATION_RECEIVED';
export const SEND_EMAIL_CONFIRMATION_FAILED = 'SEND_EMAIL_CONFIRMATION_FAILED';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CONFIRM_EMAIL = 'CONFIRM_EMAIL';
export const CONFIRM_EMAIL_REQUESTED = 'CONFIRM_EMAIL_REQUESTED';
export const CONFIRM_EMAIL_FAILED = 'CONFIRM_EMAIL_FAILED';
export const RESET_PASSWORD_REQUESTED = 'RESET_PASSWORD_REQUESTED';
export const RESET_PASSWORD_RECEIVED = 'RESET_PASSWORD_RECEIVED';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_REQUESTED = 'FORGOT_PASSWORD_REQUESTED';
export const FORGOT_PASSWORD_RECEIVED = 'FORGOT_PASSWORD_RECEIVED';
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';
export const LOGIN = 'LOGIN';
export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGIN_RECEIVED = 'LOGIN_RECEIVED';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT = 'LOGOUT';

// sets login to loading for social login
export const loginLoading = () => {
  return { type: LOGIN_REQUESTED };
};

// stops login loading state if social login failed
export const loginStop = () => {
  return { type: LOGIN_FAILED };
}

export function saveToken(payload: any) {
  return {
    type: LOGIN_RECEIVED,
    payload,
  }
}

export function setPostLoginRedirect(post_login_redirect_url: string) {
  return {
    type: SET_POST_LOGIN_REDIRECT,
    payload: {
      post_login_redirect_url:post_login_redirect_url
    }
  }
}

export function logout() {
  return (dispatch: any): any => {
    dispatch({
      payload: null,
      type: LOGOUT,
    });
  }
}

export function login(data: any) {

  return (dispatch: any, getState: any): any => {
    const { API_HOST } = getState().app;

    return dispatch(httpAction({
      method: 'POST',
      url: `${API_HOST}/auth/login/password`,
      type: LOGIN,
      data: data,
    }));
  };
}

// export function loginOld(data) {

//   return (dispatch: any, getState: any): any => {
//     const { API_HOST, CLIENT_ID, CLIENT_SECRET } = getState().app;

//     data = Object.assign({
//       client_id: CLIENT_ID,
//       client_secret: CLIENT_SECRET,
//       grant_type: 'password',
//     }, data);

//     return dispatch(httpAction({
//       method: 'POST',
//       url: `${API_HOST}/oauth/access_token`,
//       type: LOGIN,
//       data: data,
//     }));
//   };
// }

export function forgotPassword(data: any) {
  return (dispatch: any, getState: any): any => {
    const { API_HOST } = getState().app;

    return dispatch(httpAction({
      method: 'POST',
      url: `${API_HOST}/auth/password/email`,
      type: FORGOT_PASSWORD,
      data: data,
      successMessage: "Go check your email for instructions to change your password!"
    }));
  }
}

export function resetPassword(data: any) {
  return (dispatch: any, getState: any): any => {
    const { API_HOST } = getState().app;

    return dispatch(httpAction({
      method: 'POST',
      url: `${API_HOST}/auth/password/reset`,
      type: RESET_PASSWORD,
      data: data,
      successMessage: "Password was reset!"
    }));
  }
}

export function sendConfirmationEmail(data: any) {
  return (dispatch: any, getState: any): any => {
    const { API_HOST } = getState().app;

    return dispatch(httpAction({
      method: 'POST',
      url: `${API_HOST}/auth/email/confirm/send`,
      type: SEND_EMAIL_CONFIRMATION,
      data: data,
      successMessage: `Check email (confirmation sent to ${data.email})`
    }));
  }
}

export function confirmEmail(data: any) {
  return (dispatch: any, getState: any): any => {
    const { API_HOST } = getState().app;

    // Try to login user when confirming email. JWT token is going to be sent
    // from the backend on success of this API call.
    dispatch({
      type: CONFIRM_EMAIL
    });

    return dispatch(httpAction({
      method: 'POST',
      url: `${API_HOST}/auth/email/confirm`,
      type: LOGIN,
      data: data
    }));
  }
}

export function signup(data: any) {

  return (dispatch: any, getState: any): any => {
    const { API_HOST } = getState().app;

    return dispatch(httpAction({
      method: 'POST',
      url: `${API_HOST}/auth/register`,
      type: SIGNUP,
      data: data,
    }));
  };
}
