import React from 'react';
import styles from './style.module.scss';
import { connect } from 'react-redux';
import _ from 'lodash';
import { closeModal } from '../../actions';
import { Spinner } from '../etc/spinner';

class Modal extends React.Component {

	onCancel = (e, ...args) => {
		if (_.isFunction(this.props.cancel)) {
			this.props.cancel(...[e, ...args])
		}

		this.props.closeModal(...[e, ...args])
	}

	onSubmit = (e, ...args) => {
		let successful = true;
		if (_.isFunction(this.props.submit)) {
			successful = !!this.props.submit(...[e, ...args])
		}

		if(successful)
			this.props.closeModal(...[e, ...args])
		else
			console.warning("Could not close modal because submit callback returned falsey");
	}

	render() {
		const {children, title, loading} = this.props;
		const hasCancelFunc = _.isFunction(this.props.cancel);
		const hasSubmitFunc = _.isFunction(this.props.submit);
		const submitButtonText = _.get(this.props, "submitButtonText", "Save");

		// Render nothing if the "show" prop is false
		if (!this.props.modal.show) {
			return null;
		}

		return (

				<div className="modal is-active">
					<div className="modal-background" onClick={this.onCancel}></div>
					<div className={`${styles.modalCard} modal-card`}>
						<form onSubmit={this.onSubmit}>
							<header className="modal-card-head">
								<p className="modal-card-title">{title}</p>
								{ hasCancelFunc ?
									<button className="delete" aria-label="close" onClick={this.onCancel}></button>
									: null
								}

							</header>
							<section className="modal-card-body">
								{children}
							</section>
							<footer className="modal-card-foot" style={{justifyContent: 'space-between'}}>
								{
									hasCancelFunc ?
									<button className="button" onClick={this.onCancel}>Close</button>
									: null
								}

								{ hasSubmitFunc ?
									<button className="button is-primary" type="submit">
										<Spinner loading={loading}>&nbsp;</Spinner>
										<span>{submitButtonText}</span>
									</button>
									: null }
							</footer>
						</form>
					</div>
				</div>

		)
	}
}

const mapStateToProps = (state, ownProps) => {
	const { modals } = state;
	return {
		modal: _.find(modals, {id: ownProps.id}) || {},
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        closeModal: () => dispatch(closeModal(ownProps.id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
