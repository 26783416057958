
import React from "react";

const Connectedness = ({
  style = {},
  fill = "#091821",
  width = "100%",
  height = "100%",
  className = "",
  viewBox = "0 0 103.06 80.61"
}) => (
    <svg
      width={width}
      style={style}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path d="M24.29,44.68A4.49,4.49,0,1,0,26.62,53L41.8,67.5a3.71,3.71,0,0,0-.38,1.62,3.75,3.75,0,1,0,6.17-2.83l7.09-17a6.82,6.82,0,0,0,1.5.17,6.75,6.75,0,0,0,4.68-1.88l7.8,5.76a4.49,4.49,0,1,0,6.08-2.79L77.9,27.3a3.69,3.69,0,1,0-3.67-5.89L57.89,15.64A3.69,3.69,0,0,0,50.56,15L26.9,17.72A3.69,3.69,0,1,0,25,22.67l7.16,8.05a3.62,3.62,0,0,0,.23,4.15l-6.69,10A4.48,4.48,0,0,0,24.29,44.68ZM45.46,65.4a3.29,3.29,0,0,0-2.06.43L28.22,51.33a4.43,4.43,0,0,0,.54-1.9l21.12-4.32a6.79,6.79,0,0,0,2.67,3.24ZM56.19,46.72a4.08,4.08,0,1,1,4.08-4.08A4.08,4.08,0,0,1,56.19,46.72Zm.94-28.91,16.31,5.76a3.63,3.63,0,0,0,2.17,3.48l-3.16,23.1a4.47,4.47,0,0,0-2.55,1.17l-7.66-5.66a6.76,6.76,0,0,0-5.39-9.77L55.6,19A3.71,3.71,0,0,0,57.12,17.82Zm-30.38,3.3a3.66,3.66,0,0,0,.44-1.1l23.74-2.78L37.15,29.43a3.61,3.61,0,0,0-3.22-.22Zm7.59,15a3.68,3.68,0,0,0,4.39-5L52.64,18.92a3.67,3.67,0,0,0,.65.23l1.25,16.93a6.8,6.8,0,0,0-5.12,6.77L28.3,47.17a4.42,4.42,0,0,0-.65-1Z" />

    </svg>
  );

export default Connectedness;
