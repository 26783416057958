
import React from "react";

const Comments = ({
  style = {},
  fill = "#091821",
  width = "100%",
  height = "100%",
  className = "",
  viewBox = "0 0 103.06 80.61"
}) => (
    <svg
      width={width}
      style={style}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path d="M33.76,37.87c0-4.41-4.22-6.94-9.43-6.94s-9.43,2.53-9.43,6.94v1.81H33.76Z" />
      <path d="M85.24,42.92H38.75V64.51H85.24ZM63.41,59.56H43.59v-2.9H63.41Zm15.83-8.79H43.59V47.87H79.25Z" />
      <rect x="38.44" y="21.9" width="40.5" height="3.38" />
      <rect x="38.44" y="32.33" width="32.27" height="3.38" />
      <circle cx="24.33" cy="23.93" r="5.72" />
    </svg>
  );

export default Comments;
