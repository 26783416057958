import _ from 'lodash';
import { OPEN_MODAL, CLOSE_MODAL } from '../actions';

const initialState = [];

export const modals = (state = initialState, action) => {
    let new_state;

    switch (action.type) {
        case OPEN_MODAL:
        case CLOSE_MODAL:
          let a = _.unionBy([action.payload], state, 'id');
          new_state = [...a]; break;
        default: new_state = state; break;
    }

    return new_state;
};
