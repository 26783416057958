import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Icon from '../../../icon';
import { Spinner } from '../../etc/spinner';

import { closeModal, saveToken, loginLoading, loginStop } from '../../../actions';
import styles from './styles.module.scss';

class ModalLogin extends React.Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.loading && !this.props.loading && this.props.accessToken) {
      this.props.closeModal();
    }

    // if(!prevProps.accessToken && this.props.accessToken) {
    //   this.props.fetchCurrentUser();
    // }

    if(prevProps.modal.show && !this.props.modal.show) {
      // clear error when closing modal
      this.setState({ error: null });
    }
  }


  socialLogin = (provider) => {
    if (this.state.error){ this.setLoginError()}; // clear errors
    this.props.setLoginLoading();
    const protocol = window.location.protocol.replace(":","");
    var loginWindow = window.open(`${this.props.apiHost}/auth/login/${provider}?host=${window.location.hostname}&protocol=${protocol}`, 'Login', "toolbar=no, scrollbars=no, resizable=yes, top=0, left=0, width=480, height=598");

    // Close popup window if user has not logged in after three minutes
    const loginTimeout = setTimeout(() => {
      this.props.setLoginStop();
      clearInterval(loginCheckInterval);
      loginWindow.close();
      window.removeEventListener('message', handleMessage);
    }, 180000);

    const loginCheckInterval = setInterval(() => {
      if (loginWindow.closed) {
        this.props.setLoginStop();
        clearInterval(loginCheckInterval);
        clearTimeout(loginTimeout);
        window.removeEventListener('message', handleMessage);
        this.setLoginError('There was a problem signing you in.');
      }
    }, 100);

    const handleMessage = (event) => {
        if (event.source === loginWindow) {
          const {token, error} = event.data;
          if (token) {
            this.props.saveToken(token);
            window.removeEventListener('message', handleMessage);
            clearTimeout(loginTimeout);
            clearInterval(loginCheckInterval);
            loginWindow.close();
            this.props.closeModal();
          } else if (error) {
            this.setLoginError(error)
          }

        }
      }

    window.addEventListener('message', handleMessage)
  };


  setLoginError = (error = null) => {
    this.setState({ error });
  }

	render() {
    const { loading, closeModal} = this.props;
    const { error } = this.state;

		// Render nothing if the "show" prop is false
		if (!this.props.modal.show) {
			return null;
		}

		return (

				<div className="modal is-active">
					<div className="modal-background" onClick={closeModal}></div>
					<div className={styles.modalCard}>
            <header className={styles.modalHeader}>
              <Icon
                name="logo-no-beta"
                width={200}
                fill="white"
                style={{display: 'block', cursor: 'default'}}
              />
              <button className={`delete ${styles.close}`} aria-label="close" onClick={closeModal}></button>
            </header>
            <section className={styles.loginContent}>
              <p>Sign in to discuss and discover news topics you care about.</p>
              <div className={styles.infoBar}>
                {error ? <p className="help is-danger">{error}</p> : null}
                {loading ? <Spinner loading/> : null}
              </div>

              <div className={styles.loginOptions}>
                <button id="login-with-facebook" className={`button is-primary ${styles.btnSocial} ${styles.facebook}`} onClick={()=>this.socialLogin('facebook')} data-network="Facebook">
                  <FontAwesomeIcon icon={['fab', 'facebook-f']} className={styles.socialLogo} />
                  <span>Sign in with Facebook</span>
                </button>
                <button id="login-with-linkedin" className={`button is-primary ${styles.btnSocial} ${styles.linkedin}`} onClick={()=>this.socialLogin('linkedin')} data-network="Linkedin">
                  <FontAwesomeIcon icon={['fab', 'linkedin-in']} className={styles.socialLogo} />
                  <span>Sign in with LinkedIn</span>
                </button>
              </div>
              <footer className={styles.modalFooter}>
                <p>We will never post to Facebook or LinkedIn on your behalf. By signing in to Fiskkit you agree to our <Link to='/terms' onClick={closeModal}>terms</Link>.</p>
              </footer>
            </section>
					</div>
				</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	const { app, auth, modals, loading } = state;
	return {
    accessToken: _.get(auth, 'access_token'),
    apiHost: _.get(app, 'API_HOST'),
    modal: _.find(modals, {id: ownProps.id}) || {},
    loading: !!_.get(loading, "LOGIN")
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setLoginStop: () => dispatch(loginStop()),
        setLoginLoading: () => dispatch(loginLoading()),
        closeModal: () => dispatch(closeModal(ownProps.id)),
        saveToken: (access_token) => dispatch(saveToken({ access_token })),
        // fetchCurrentUser: () => dispatch(fetchCurrentUser()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalLogin);
