import React from 'react';
import style from './slide.module.scss';

const FirstSlide = (props) => {

    return (
      <div className={style.wrapper}>
       <h1 className={style.title}>Welcome {props.userName}!</h1>
       <h2 className={style.subTitle}>Let’s show you around.</h2>
       <p className={style.slogan}>Fiskkit is a social good venture building tools to elevate public discourse.</p>
     </div>)
}

export default FirstSlide;
