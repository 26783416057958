import React from 'react';

import DropdownIndicator from './indicator';
import styles from './dropdown.module.scss';


export default class Dropdown extends React.Component {
  constructor(props) {
    super(props)
    this.dropdownRef = React.createRef();

    this.state = {
      displayMenu: false,
    };
  }

  static defaultProps = {
    arrow: true
  }

  toggleDropdownMenu = (event) => {
    const node = this.dropdownRef.current;
    if (this.state.displayMenu && node && node.contains(event.target)) {
      this.setState({
        displayMenu: false,
      });
    } else {
      this.openDropdownMenu(event);
    }
  }

  openDropdownMenu = (event) => {
    event.preventDefault();

    document.addEventListener('mousedown', this.closeDropdownMenu);

    this.setState({
      displayMenu: true,
    });
  }

  closeDropdownMenu = (event) => {
    event.preventDefault();

    document.removeEventListener('mousedown', this.closeDropdownMenu);

    const node = this.dropdownRef.current;
    if (node && !node.contains(event.target)) {
      this.setState({
        displayMenu: false,
      });
    }
  }

  render() {
    const { displayMenu } = this.state;

    return (
      <div className={this.props.containerClassName} style={this.props.containerStyle} ref={this.dropdownRef} onClick={this.toggleDropdownMenu}>
        <div className={styles.labelWrapper} onClick={this.toggleDropdownMenu}>
          {this.props.label ?
              <span className={`${styles.dropdownLabel} ${this.props.labelClassName}`} style={this.props.labelStyle} onClick={this.toggleDropdownMenu} aria-haspopup="true" aria-controls="dropdown-menu">
                {this.props.label}
              </span>
          :null}
          {this.props.arrow ?
            <span className={styles.triangleContainer}>
              <DropdownIndicator active={displayMenu} />
            </span>
          :null}
        </div>

        {displayMenu ?
          <ul className={`${styles.dropdownMenu} ${this.props.menuClassName}`} style={this.props.menuStyle} id="dropdown-menu">
            {/* children should be a list of DropdownItem */}
            {this.props.children}
          </ul>
        :null}
      </div>
    );
  }
}
