import { combineReducers } from 'redux';

import {app} from './app';
import {auth} from './auth';
import {courses} from './courses';
import {instructors} from './instructors';
import {discuss} from './discuss';
import {home} from './home';
import {loading} from './loading';
import {login} from './login';
import {modals} from './modals';
import {videos} from './videos';
import {notifications} from './notifications';
import {signup} from './signup';
import {tags} from './tags';
import {user} from './user';
import { groupInsights } from './insights'

const reducers = combineReducers({
  app,
  auth,
  courses,
  discuss,
  groupInsights,
  home,
  instructors,
  loading,
  login,
  modals,
  notifications,
  signup,
  tags,
  user,
  videos
})

export default reducers;
