import { EXP_CLASSROOM, EXP_CORE, FACEBOOK_APP_ID, FACEBOOK_APP_ID_DEV } from './../constants';


export const core = {
  API_HOST: 'https://api-prod.fiskkit.com', // May not be the correct value
  APP_EXPERIENCE: EXP_CORE,
  FACEBOOK_APP_ID: FACEBOOK_APP_ID,
  ENVIRONMENT_SWITCH_URL: 'https://classroom.fiskkit.com' 
};

export const classroom = {
  API_HOST: 'https://api-prod.fiskkit.com', // May not be the correct value
  APP_EXPERIENCE: EXP_CLASSROOM,
  FACEBOOK_APP_ID: FACEBOOK_APP_ID,
  ENVIRONMENT_SWITCH_URL: 'https://fiskkit.com'
};

export const core_development = {
  API_HOST: 'https://developer-api.fiskkit.com',
  APP_EXPERIENCE: EXP_CORE,
  FACEBOOK_APP_ID: FACEBOOK_APP_ID_DEV,
  ENVIRONMENT_SWITCH_URL: 'https://devclassroom.fiskkit.com'
};

export const classroom_development = {
  API_HOST: 'https://developer-api.fiskkit.com',
  APP_EXPERIENCE: EXP_CLASSROOM,
  FACEBOOK_APP_ID: FACEBOOK_APP_ID_DEV,
  ENVIRONMENT_SWITCH_URL: 'https://dev.fiskkit.com'
};

export const local = {
  API_HOST: 'http://ff.local:8001', // May not be the correct value
  APP_EXPERIENCE: EXP_CORE,
  FACEBOOK_APP_ID: FACEBOOK_APP_ID_DEV,
  ENVIRONMENT_SWITCH_URL: 'http://ff.local:3000'
};

export const classroom_local = {
  API_HOST: 'http://ff.local:8001', // May not be the correct value
  APP_EXPERIENCE: EXP_CLASSROOM,
  FACEBOOK_APP_ID: FACEBOOK_APP_ID_DEV,
  ENVIRONMENT_SWITCH_URL: 'http://localhost:3000'
};

// export const temporaryAccessToken = 'vS7zk0RWVS97amtDGbDkvesSX75oBtCeSrsamOvN';
export const temporaryAccessToken = 'TflAXnc1UYqEMetF4GE5VSQb5voQfHMiWZU33LJS';
