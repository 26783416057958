import React from 'react';
import style from './slide.module.scss';

const TextSlide = () => {

    return (
    <div className={style.wrapperSecondSlide}>
      <p className={style.paragraph + " "+ style.paragraphTitle}>Fiskkit helps students practice critical thinking.</p>
      <p className={style.paragraph}>It only takes 30 seconds to assign an article, but it takes 30 minutes for students to think through it critically.</p>
      <p className={style.paragraph}>Students can then have a high quality online discussion on the article under the teacher’s supervision.</p>
      <p className={style.paragraph}>Practicing on multiple articles makes it possible for Fiskkit to quantify the improvement in student critical thinking.</p>
     </div>
   )
}

export default TextSlide;
