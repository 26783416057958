import _ from 'lodash';
import { FETCH_COURSES_RECEIVED } from '../actions/courses';

const initialState = [];

export const courses = (state = initialState, action) => {
    let new_state;
    
    switch (action.type) {
        case FETCH_COURSES_RECEIVED: new_state = Object.assign(state, action.payload.courses); break;
        default: new_state = state; break;
    }
    
    return new_state;
};
